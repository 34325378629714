import { basePath, apiVersion } from './config';

export async function getPieStatsApi({
  token,
  type,
  start,
  end,
  floor,
  dyalisis,
}) {
  const url = `${basePath}/${apiVersion}/pie-chart-stats?type=${type}&start=${start}&end=${end}&floor=${floor}&dyalisis=${dyalisis}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getLineStatsApi({ token, type, start, end, floor }) {
  const url = `${basePath}/${apiVersion}/line-chart-stats?type=${type}&start=${start}&end=${end}&floor=${floor}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getMedicineStatsApi({
  token,
  type,
  month,
  floor,
  start,
  end,
}) {
  const url = `${basePath}/${apiVersion}/medicine-stats?type=${type}&month=${month}&floor=${floor}&start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getGeneralStatsApi({ token, start, end }) {
  const url = `${basePath}/${apiVersion}/general-stats?start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getBubbleStatsApi({ token, diagnostic }) {
  const url = `${basePath}/${apiVersion}/bubble-chart-stats?cause=${diagnostic}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getTurnsStatsApi({
  token,
  month,
  year,
  id,
  type,
  status,
  day,
  format,
  start,
  end,
  socialWorks,
  studieType,
}) {
  const url = `${basePath}/${apiVersion}/turns-stats?month=${month}&year=${year}&id=${id}&type=${type}&status=${status}&day=${day}&format=${format}&start=${start}&end=${end}&socialWorks=${socialWorks}&studieType=${studieType}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getDyalisisPresentsStatsApi(token, start, end) {
  const url = `${basePath}/${apiVersion}/dyalisis-presents?start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getFiltersUseStatsApi({ token, start, end }) {
  const url = `${basePath}/${apiVersion}/filters-use?start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getFiltersByPatientApi({ token, start, end }) {
  const url = `${basePath}/${apiVersion}/filters-patient-use?start=${start}&end=${end}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
