import React, { useEffect, useState } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
  Collapse,
  Button,
  notification,
  Tooltip,
  Checkbox,
  message,
} from 'antd';
import {
  onBedRead,
  onIncomeCreate,
  onIncomeUpdate,
  onCityRead,
  onSocialWorkRead,
  onIncomeHistoryUpdate,
} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { origin, queryDyalisisReasons } from '../../utils/constants';
import locale from 'antd/es/date-picker/locale/es_ES';
import SocialWorkForm from '../SocialWorkForm';
import Modal from '../Modal';
import CityForm from '../CityForm/CityForm';
import PermissionsAsigner from '../PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import { getAccessTokenApi } from '../../api/auth';
import { getPatientApi } from '../../api/patient';
import {
  CheckCircleOutlined,
  CloseOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { getIncomeApi, getLastIncomeApi } from '../../api/income';
import Spin from '../Spin';
import { set } from 'react-ga';

export default function IncomeForm(props) {
  const { id, incomeType } = props.match.params;
  const { path } = props.match;
  const [income, setIncome] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disablePatientData, setDisablePatientData] = useState(false);
  const [provisionalDni, setProvisionalDni] = useState(false);
  const [patientExist, setPatientExist] = useState(false);

  const { incomesActive } = useSelector((store) => store.incomeData);
  const { beds } = useSelector((store) => store.bedData);
  const { cities } = useSelector((store) => store.cityData);
  const { socialWorks } = useSelector((store) => store.socialWorkData);
  const { name, lastname, role, type } = useSelector(
    (store) => store.userData.user
  );

  const { floors } = useSelector((store) => store.tenantData);

  let floorType = floors?.find(
    (floor) => floor.id === incomeType && floor?.type
  );

  const dispatch = useDispatch();

  const [incomeForm] = Form.useForm();

  useEffect(() => {
    if (
      (role === ROLES.VIEW.value && type === 'Medico') ||
      role === ROLES.EMPLOYEE.value
    )
      props.history.push('/');

    income
      ? dispatch(onBedRead(income?.type))
      : dispatch(onBedRead(incomeType));
    dispatch(onCityRead());
    dispatch(onSocialWorkRead());
  }, [income]);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      id &&
        (await getIncomeApi(id, token)
          .then(async (response) => {
            setIncome(response.income);
            if (response.income?.patient) {
              setDisablePatientData(true);
              setPatientExist(true);
            } else {
              response.income.dni === 9911 && setProvisionalDni(true);
            }
          })
          .catch((err) => {
            notification['warning']({
              message: 'Error del servidor, vuelva atras.',
            });
          }));
    };
    getData();
  }, [id]);

  const addSocialWork = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva obra social');
    setModalContent(
      <SocialWorkForm setIsVisibleModal={setIsVisibleModalForm} />
    );
  };

  const addCity = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva ciudad');
    setModalContent(<CityForm setIsVisibleModal={setIsVisibleModalForm} />);
  };

  const onFinish = async (values) => {
    setLoading(true);

    values.heigth && values.weigth
      ? (values.imc = (values.weigth / Math.pow(values.heigth, 2)).toFixed(2))
      : (values.imc = '');
    values.type = id ? income.type : incomeType;

    if (!id) {
      let exist = await incomesActive?.find((income) => {
        return income.dni == values.dni;
      });
      if (exist && !provisionalDni) {
        notification['error']({
          message: `El paciente con este dni ya esta cargado en la cama ${exist?.bed.name}`,
        });
        setLoading(false);
        return;
      }
      values.user = `${name} ${lastname}`;
      await dispatch(onIncomeCreate(values));
      setLoading(false);
    } else {
      if (path === '/edit-history-income/:id') {
        values.bed = income.bed._id;
        await dispatch(onIncomeHistoryUpdate(values, id));
        setLoading(false);
      } else {
        if (values.bed.length < 12) values.bed = income.bed._id;
        await dispatch(onIncomeUpdate(values, id));
        setLoading(false);
      }
    }
  };

  const onFinishFailed = (e) => {
    if (incomeForm.getFieldsError()) {
      notification['warning']({
        message:
          'Debe completar todos los campos obligatorios (*). Revise los datos filiatorios.',
      });
    }
  };

  const searchPerson = async (dni) => {
    setLoading(true);
    let token = await getAccessTokenApi();
    getPatientApi(token, dni)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({
            message: response.message,
          });
        } else {
          if (response.patients.length === 0) {
            setDisablePatientData(false);
            message.warning(
              'No existe un paciente con ese dni. A continuacion complete los datos del paciente.'
            );
          } else {
            setDisablePatientData(true);
            incomeForm.setFieldsValue({
              name: response.patients[0]?.name,
              birthDate: moment(response.patients[0]?.birthDate),
              socialWork: response.patients[0]?.socialWork,
              sex: response.patients[0]?.sex,
              affiliate: response.patients[0]?.affiliate,
              city: response.patients[0]?.city,
            });
            incomeForm.setFieldsValue({
              age: moment(incomeForm.getFieldValue('date')).diff(
                incomeForm.getFieldValue('birthDate'),
                'years'
              ),
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const getHistoryData = async () => {
    const token = await getAccessTokenApi();
    await getLastIncomeApi(token, incomeForm.getFieldValue('dni')).then(
      (response) => {
        response?.code === 200 &&
          incomeForm.setFieldsValue({
            personalRecord: response?.income?.personalRecord,
            surgicalRecord: response?.income?.surgicalRecord,
            toxicHabits: response?.income?.toxicHabits,
            regularMediation: response?.income?.regularMediation,
            familyRecord: response?.income?.familyRecord,
            allergy: response?.income?.allergy,
            complementaryStudies: response?.income?.complementaryStudies,
          });
      }
    );
  };

  if (id && !income) {
    return <Spin />;
  }

  return (
    <div>
      <Form
        form={incomeForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Collapse defaultActiveKey={id && 1}>
          <Collapse.Panel header="Datos Filiatorios (*)" key={1}>
            <Row gutter={24}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="date"
                  rules={[{ required: true, message: 'Debe cargar una fecha' }]}
                  label="Fecha de Ingreso"
                  initialValue={id ? moment(income.date) : moment()}
                  labelCol={{ span: 24 }}
                >
                  <DatePicker
                    placeholder="Seleccione fecha de ingreso (*)"
                    style={{ width: '100%' }}
                    format="DD-MM-YYYY HH:mm"
                    showTime
                    locale={locale}
                    showToday={false}
                    inputReadOnly
                    onSelect={(value) =>
                      incomeForm.setFieldsValue({ date: moment(value) })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="bed"
                  initialValue={id && income?.bed?.name}
                  rules={[
                    {
                      required: true,
                      message: 'Debe seleccionar una habitación',
                    },
                  ]}
                  label="Cama"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    showSearch={window.screen.width > 500 && true}
                    inputMode="none"
                    placeholder="Seleccione un habitación.. (*)"
                    optionFilterProp="children"
                    disabled={path === '/edit-history-income/:id' && true}
                    notFoundContent="No hay camas disponibles"
                  >
                    {beds
                      ? beds.map((bed, index) => (
                          <Select.Option key={index} value={bed._id}>
                            {bed.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="dni"
                  initialValue={id && income?.dni}
                  rules={[
                    {
                      required: true,
                      message: 'Debe cargar el dni. Sin puntos',
                    },
                  ]}
                  label="DNI"
                  help={
                    !incomeForm.getFieldValue('dni') && (
                      <p>
                        Presione enter o el boton de la lupa para continuar con
                        el formulario <br />
                        Si no sabemos el DNI del paciente tickear la celda "DNI
                        Provisorio". La cual nos pone un dni ficticio 9911 para
                        luego poder corregirlo o encontrarlos con mayor
                        facilidad <br />
                        NO INVENTAR EL DNI
                      </p>
                    )
                  }
                  tooltip={
                    patientExist &&
                    id && {
                      icon: (
                        <Tooltip title="Paciente Guardado">
                          <CheckCircleOutlined />
                        </Tooltip>
                      ),
                    }
                  }
                  labelCol={{ span: 24 }}
                >
                  <Input.Search
                    disabled={
                      !id
                        ? disablePatientData || provisionalDni
                        : income.dni === 9911
                        ? false
                        : role === ROLES.OWNER.value ||
                          role === ROLES.SECRETARY.value
                        ? false
                        : true
                    }
                    inputMode="numeric"
                    placeholder="Ej: 34256389"
                    onChange={(e) => {
                      incomeForm.setFieldsValue({
                        dni: tranformText(e.target.value),
                      });
                    }}
                    type="number"
                    enterButton
                    onSearch={(value) => {
                      searchPerson(value);
                      setPatientExist(true);
                    }}
                    suffix={
                      (!id ||
                        role === ROLES.OWNER.value ||
                        role === ROLES.SECRETARY.value ||
                        income.dni === 9911) && (
                        <>
                          {disablePatientData || patientExist ? (
                            <Tooltip title={'Editar Formulario'}>
                              <Button
                                type="link"
                                size="small"
                                onClick={async () => {
                                  incomeForm.resetFields();
                                  setDisablePatientData(false);
                                  setPatientExist(false);
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={!disablePatientData && 'DNI Provisorio'}
                            >
                              <Checkbox
                                onChange={(e) => {
                                  incomeForm.setFieldsValue({
                                    dni: e.target.checked ? 9911 : income?.dni,
                                  });
                                  setProvisionalDni(e.target.checked);
                                }}
                                style={{ color: '#fff' }}
                                disabled={disablePatientData}
                              />
                            </Tooltip>
                          )}
                        </>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              {incomeForm.getFieldValue('dni') && (
                <>
                  <Col xs={24} sm={12} md={8}>
                    <Tooltip
                      title={
                        disablePatientData &&
                        'Para editar debe hacerlo buscando al paciente en la solapa ambulatorio'
                      }
                    >
                      <Form.Item
                        name="name"
                        initialValue={id ? income.name : ''}
                        rules={[
                          {
                            required: true,
                            message: 'Debe cargar el Apellido y Nombre',
                          },
                        ]}
                        label="Apellido y Nombre"
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          disabled={disablePatientData}
                          style={{ textTransform: 'capitalize' }}
                          placeholder="Apellido Nombre"
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Tooltip
                      title={
                        disablePatientData &&
                        'Para editar debe hacerlo buscando al paciente en la solapa ambulatorio'
                      }
                    >
                      <Form.Item
                        name="birthDate"
                        label="Fecha de nacimiento"
                        rules={[
                          {
                            required: true,
                            message: 'Debe cargar fecha de nacimiento',
                          },
                        ]}
                        initialValue={id && moment(income.birthDate)}
                        labelCol={{ span: 24 }}
                      >
                        <DatePicker
                          disabled={disablePatientData}
                          placeholder="Ej. 24-03-1997"
                          style={{ width: '100%' }}
                          format="DD-MM-YYYY"
                          onChange={(e) => {
                            if (e) {
                              e > moment().endOf('day')
                                ? message.warning('Fecha mayor a la actual')
                                : moment().diff(e, 'years') >= 110 &&
                                  message.warning('Edad mayor a 110 años');

                              incomeForm.setFieldsValue({
                                age: moment().diff(e._d, 'years'),
                              });
                            }
                          }}
                          locale={locale}
                          showToday={false}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="age"
                      initialValue={id && income.age}
                      rules={[
                        {
                          required: true,
                          message: 'Debe cargar la edad del paciente',
                        },
                      ]}
                      label="Edad"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        disabled={disablePatientData}
                        placeholder="Edad (*)"
                        onChange={(e) => {
                          incomeForm.setFieldsValue({
                            age: tranformText(e.target.value),
                          });
                        }}
                        type="number"
                        inputMode="numeric"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="weigth"
                      initialValue={id ? income.weigth : ''}
                      label="Peso"
                      labelCol={{ span: 24 }}
                      step="0.01"
                    >
                      <Input
                        placeholder="74"
                        suffix="Kg"
                        type="number"
                        inputMode="numeric"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="heigth"
                      initialValue={id ? income.heigth : ''}
                      label="Talla"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        placeholder="1.72"
                        suffix="m"
                        type="number"
                        step="0.01"
                        inputMode="decimal"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="sex"
                      initialValue={id && income.sex}
                      rules={[
                        { required: true, message: 'Debe cargar el sexo' },
                      ]}
                      label="Sexo"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        placeholder="Sexo (*)"
                        disabled={disablePatientData}
                        inputMode="none"
                      >
                        <Select.Option value="Masculino">
                          Masculino
                        </Select.Option>
                        <Select.Option value="Femenino">Femenino</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="socialWork"
                      initialValue={id && income.socialWork}
                      rules={[
                        {
                          required: true,
                          message: 'Debe cargar la obra social',
                        },
                      ]}
                      label="Obra social"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        placeholder="Obra Social"
                        showSearch
                        optionFilterProp="children"
                        style={{ textTransform: 'uppercase' }}
                      >
                        <Select.Option disabled>
                          <Button type="link" onClick={addSocialWork}>
                            + Nueva obra social
                          </Button>
                        </Select.Option>
                        {socialWorks &&
                          socialWorks.map((socialWork, index) => (
                            <Select.Option
                              key={index}
                              value={socialWork.name}
                              style={{ textTransform: 'uppercase' }}
                            >
                              {socialWork.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="affiliate"
                      initialValue={id && income.affiliate}
                      label="Nro de afiliado"
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder="Nro de afiliado" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="contactPhone"
                      initialValue={id && income.contactPhone}
                      label="Teléfono de contacto"
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder="385 4883790 (Hija)" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="city"
                      initialValue={id && income.city}
                      label="Ciudad de origen"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        placeholder="Seleccione una ciudad"
                        showSearch
                        optionFilterProp="children"
                        style={{ textTransform: 'capitalize' }}
                      >
                        <Select.Option disabled>
                          <Button type="link" onClick={addCity}>
                            + Nueva ciudad
                          </Button>
                        </Select.Option>
                        {cities &&
                          cities.map((city, index) => (
                            <Select.Option
                              key={index}
                              value={city.name}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {city.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="doctor"
                      initialValue={id ? income.doctor : ''}
                      label="Medico de cabecera"
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder="Médico de cabecera" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="origin"
                      initialValue={id && income.origin}
                      rules={[
                        {
                          required: true,
                          message: 'Debe cargar el origen interno',
                        },
                      ]}
                      label="Origen interno"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        placeholder="Origen"
                        optionFilterProp="children"
                        inputMode="none"
                      >
                        {origin &&
                          origin.map((origin, index) => (
                            <Select.Option key={index} value={origin.name}>
                              {origin.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {floorType?.type === 'Dialisis' && (
                    <>
                      <Col xs={24} sm={12} md={8}>
                        <Form.Item
                          name="direction"
                          initialValue={id ? income?.direction : ''}
                          label="Dirección"
                          labelCol={{ span: 24 }}
                        >
                          <Input placeholder="Dirección" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <Form.Item
                          name="typePatientDyalisis"
                          initialValue={id && income?.typePatientDyalisis}
                          rules={[
                            {
                              required: true,
                              message: 'Debe indicar el tipo de paciente',
                            },
                          ]}
                          label="Tipo de paciente"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            placeholder="Tipo de paciente (*)"
                            inputMode="none"
                          >
                            <Select.Option value="Agudo">Agudo</Select.Option>
                            <Select.Option value="Cronico">
                              Cronico
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <Form.Item
                          name="typeDyalisis"
                          initialValue={id && income?.typeDyalisis}
                          rules={[
                            {
                              required: true,
                              message: 'Debe indicar el tipo de diálisis',
                            },
                          ]}
                          label="Tipo de diálisis"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            placeholder="Tipo de diálisis (*)"
                            inputMode="none"
                          >
                            <Select.Option value="DPCA">DPCA</Select.Option>
                            <Select.Option value="DPA">DPA</Select.Option>
                            <Select.Option value="Hemodialisis">
                              Hemodialisis
                            </Select.Option>
                            <Select.Option value="HDF">HDF</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col xs={24} sm={12} md={24}>
                    <Form.Item
                      name="queryReason"
                      initialValue={id ? income.queryReason : ''}
                      rules={[
                        {
                          required: true,
                          message: 'Debe cargar motivo de la consulta',
                        },
                      ]}
                      label={
                        floorType?.type === 'Dialisis'
                          ? 'Etiologia de la IRC'
                          : 'Motivo de consulta'
                      }
                      labelCol={{ span: 24 }}
                    >
                      {floorType?.type === 'Dialisis' ? (
                        <Select placeholder="Seleccione etiologia de la IRC">
                          {queryDyalisisReasons?.map((item) => (
                            <Select.Option value={item}>{item}</Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <Input.TextArea
                          autoSize={{ minRows: 2 }}
                          placeholder="Motivo de consulta"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="Antecedentes" key={2}>
            <Button
              type="link"
              onClick={(e) => {
                getHistoryData();
              }}
              disabled={
                !disablePatientData ||
                (id &&
                  (income?.personalRecord ||
                    income?.surgicalRecord ||
                    income?.toxicHabits ||
                    income?.regularMediation ||
                    income?.familyRecord ||
                    income?.allergy ||
                    income?.complementaryStudies))
              }
            >
              <HistoryOutlined /> Consultar Antecedentes
            </Button>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="personalRecord"
                  initialValue={id && income.personalRecord}
                  label="Antecedentes personales"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    disabled={role <= 0}
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción antecedentes personales.."
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="surgicalRecord"
                  initialValue={id && income.surgicalRecord}
                  label="Antecedentes quirurgicos"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción antecedentes quirurgicos.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="toxicHabits"
                  initialValue={id && income.toxicHabits}
                  label="Hábitos Tóxicos:"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción habitos tóxicos.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="regularMediation"
                  initialValue={id && income.regularMediation}
                  label="Medicación Habitual:"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción mediación habitual.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="familyRecord"
                  initialValue={id && income.familyRecord}
                  label="Antecedentes Heredofamiliares"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción antecedentes heredofamiliares.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="allergy"
                  initialValue={id && income.allergy}
                  label="Alergias:"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción alergias.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  name="complementaryStudies"
                  initialValue={id && income.complementaryStudies}
                  label="Estudios Complementarios"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    placeholder="Descripción estudios complementarios.."
                    disabled={role <= 0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="Enfermedad Actual" key={3}>
            <PermissionsAsigner requiredRole={ROLES.ADMIN}>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="historyCurrentIllnes"
                    initialValue={id && income.historyCurrentIllnes}
                    label="Antecedentes de la enfermedad actual"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4 }}
                      placeholder="Descripción de antecedentes.."
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="physicalExam"
                    initialValue={
                      id
                        ? !income.physicalExam
                          ? 'FC:    TA:     SAT 02:    Afebril  \nR1R2 NF, Silencios Libres,Sin IY, Sin Edemas en MMII. MV+ Pulmones Limpios. Abdomen Blando Depresible no doloroso,RHA+. Lucido, sin signos de foco motor ni meningeo.'
                          : income.physicalExam
                        : 'FC:     TA:     SAT 02:    Afebril \nR1R2 NF, Silencios Libres,Sin IY, Sin Edemas en MMII. MV+ Pulmones Limpios. Abdomen Blando Depresible no doloroso,RHA+. Lucido, sin signos de foco motor ni meningeo.'
                    }
                    label="Examen fisico"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4 }}
                      placeholder="Descripción examen fisico..."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="laboratory"
                    initialValue={id && income.laboratory}
                    label="Laboratorio"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder="Descripción laboratorio de ingreso.."
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="ecg"
                    initialValue={
                      id
                        ? !income.ecg
                          ? 'Ritmo:    FC:     PR:      QRS:     QT:      EJE:      \nConclusión:  '
                          : income.ecg
                        : 'Ritmo:    FC:     PR:      QRS:     QT:      EJE:      \nConclusión:  '
                    }
                    label="ECG"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder="Descripción del ECG.."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="rxChest"
                    initialValue={id && income.rxChest}
                    label={
                      floorType?.type === 'Dialisis'
                        ? 'Ingreso a Diálisis'
                        : 'Rx de Torax'
                    }
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder={
                        floorType?.type === 'Dialisis'
                          ? 'Lugar, tipo de acceso,frecuencia, llegada al centro, debe constar si ingreso ambulatorio o internado.'
                          : 'Descripción de la radiografia de torax en el ingreso..'
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="otherStudies"
                    initialValue={id && income.otherStudies}
                    label="Otros estudios"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder="Otros estudios.."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="diagnostic"
                    initialValue={id && income.diagnostic}
                    label="Diagnostico presuntivo"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder="Descripción de diagnostico.."
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="conduct"
                    initialValue={id && income.conduct}
                    label="Conducta"
                    labelCol={{ span: 24 }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder="Descripción conducta del paciente.."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </PermissionsAsigner>
          </Collapse.Panel>
        </Collapse>

        <Row gutter={24} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%', marginTop: '10px' }}
                disabled={loading || !incomeForm.getFieldValue('dni')}
                loading={loading}
              >
                {id ? 'Actualizar Ingreso' : 'Guardar Ingreso'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function tranformText(text) {
  const url = text.replace('.', '');
  return url.toLowerCase();
}
