import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';
import moment from 'moment';

export function PdfIncome({ income, type, news }) {
  return (
    <View>
      <Text
        style={styleBody.subtitle}
        render={() =>
          type === 'Ingreso'
            ? `Cama: ${income.bed.name} | ${income.name} - ${income.age} años`
            : `${income.name} - ${income.age} años`
        }
      />
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Fecha de nacimiento:</Text>
        <Text style={styleBody.text}>
          {moment(income.birthDate).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Fecha de ingreso:</Text>
        <Text style={styleBody.text}>
          {moment(income.date).format('DD/MM/YYYY HH:mm')}
        </Text>
      </View>
      <View
        render={() =>
          type === 'Epicrisis' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Fecha de alta:{' '}
                <Text style={styleBody.text}>
                  {moment(income.dischargeDate).format('DD/MM/YYYY')}
                </Text>
              </Text>
            </View>
          )
        }
      />
      <View style={styleBody.data}>
        <Text
          style={styleBody.textTitle}
          render={() =>
            income.bed?.bedType === 'Dialisis'
              ? 'Etiologia de la IRC:'
              : ' Motivo de consulta:'
          }
        ></Text>
        <Text style={styleBody.text}>{income.queryReason}</Text>
      </View>
      <View style={styleBody.personalData}>
        <Text style={styleBody.textTitle}>Sexo:</Text>
        <Text style={styleBody.text}>{income.sex}</Text>
        <Text style={styleBody.textTitle}>DNI:</Text>
        <Text style={styleBody.text}>{income.dni}</Text>
        <Text style={styleBody.textTitle}>Talla:</Text>
        <Text style={styleBody.text}>{income.height}</Text>
        <Text style={styleBody.textTitle}>Peso:</Text>
        <Text style={styleBody.text}>{income.weight}</Text>
        <Text style={styleBody.textTitle}>IMC:</Text>
        <Text style={styleBody.text}>{income.imc}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Ciudad de origen:</Text>
        <Text style={styleBody.text}>{income.city} </Text>
        <Text style={styleBody.textTitle}> - Origen interno:</Text>
        <Text style={styleBody.text}>{income.origin}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Medico de cabecera:</Text>
        <Text style={styleBody.text}>{income.doctor}</Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>Obra social:</Text>
        <Text style={styleBody.textUppercase}>{income.socialWork} </Text>
        <Text style={styleBody.textTitle}> - Nro de afiliado:</Text>
        <Text style={styleBody.text}>{income.affiliate}</Text>
      </View>
      <View
        render={() =>
          income.bed?.bedType === 'Dialisis' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>Tipo de paciente: </Text>
              <Text style={styleBody.textUppercase}>
                {income?.typePatientDyalisis}{' '}
              </Text>
              <Text style={styleBody.textTitle}> - Tipo de diálisis: </Text>
              <Text style={styleBody.text}>{income?.typeDyalisis}</Text>
            </View>
          )
        }
      />
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Alergias: <Text style={styleBody.text}>{income.allergy}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Antecedentes:{' '}
          <Text style={styleBody.text}>{income.personalRecord}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Antecedentes quirurgicos:{' '}
          <Text style={styleBody.text}>{income.surgicalRecord}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Antecedentes toxicos:{' '}
          <Text style={styleBody.text}>{income.toxicHabits}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Antecedentes heredofamiliares:{' '}
          <Text style={styleBody.text}>{income.familyRecord}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Estudios previos:{' '}
          <Text style={styleBody.text}>{income.complementaryStudies}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Medicación habitual:{' '}
          <Text style={styleBody.text}>{income.regularMediation}</Text>
        </Text>
      </View>
      <View style={styleBody.data}>
        <Text style={styleBody.textTitle}>
          Antecedentes de la enfermedad actual:{' '}
          <Text style={styleBody.text}>{income.historyCurrentIllnes}</Text>
        </Text>
      </View>
      <View
        style={styleBody.data}
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Examen fisico:{' '}
                <Text style={styleBody.text}>{income.physicalExam}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Laboratorio:{' '}
                <Text style={styleBody.text}>{income.laboratory}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                ECG: <Text style={styleBody.text}>{income.ecg}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                {income.bed?.bedType === 'Dialisis'
                  ? 'Ingreso a Dialisis:'
                  : 'Radiografia de torax:'}
                <Text style={styleBody.text}>{income.rxChest}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Otros estudios:{' '}
                <Text style={styleBody.text}>{income.otherStudies}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Conducta: <Text style={styleBody.text}>{income.conduct}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Ingreso' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Diagnostico presuntivo:{' '}
                <Text style={styleBody.text}>{income.diagnostic}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          news && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Novedades: <Text style={styleBody.text}>{income.news}</Text>
              </Text>
            </View>
          )
        }
      />
      <View
        render={() =>
          type === 'Epicrisis' && (
            <View style={styleBody.data}>
              <Text style={styleBody.textTitle}>
                Epicrisis:{' '}
                <Text style={styleBody.text}>{income.epicrisis}</Text>
              </Text>
            </View>
          )
        }
      />
    </View>
  );
}
