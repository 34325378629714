import React from 'react';
import { Page } from '@react-pdf/renderer';
import {
  PdfHeader,
  PdfIncome,
  PdfTitle,
  PdfFooter,
  PdfSignature,
} from './index';
import { styleBody } from './styles';

export function PageIncome({
  income,
  title,
  signature,
  news,
  organization,
  tenant,
  user,
}) {
  return (
    <Page style={styleBody.body}>
      <PdfHeader
        patientData={income}
        organization={organization}
        tenant={tenant}
      />
      <PdfTitle
        title={title == 'Ingreso' ? 'Anamnesis y examen físico' : title}
      />
      <PdfIncome income={income} type={title} news={news} />
      {user && (
        <PdfSignature
          signature={signature}
          name={user?.name}
          lastname={user?.lastname}
          medicalSpeciality={user?.medicalSpeciality}
          userType={user?.type}
          mp={user?.mp}
          licenseType={user?.licenseType}
        />
      )}
      <PdfFooter type={news || income.type} />
    </Page>
  );
}
