import React from 'react';
import { Text, Image, View } from '@react-pdf/renderer';
import { styleHeader, styleBody } from './styles';

export function PdfHeader({
  patientData,
  organization,
  tenant,
  period,
  tenantLogo,
}) {
  const image =
    patientData?.type === 'cardio'
      ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`
      : `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
          tenantLogo || 'logo'
        }.png`;
  return (
    <View style={styleHeader.header} fixed>
      <Text style={styleHeader.headerText}>
        {organization} {patientData && ` - Historia Clinica`}
        <Text style={styleHeader.headerText}>
          {period && (
            <Text style={styleBody.textCapitalize}> - Periodo: {period}</Text>
          )}
        </Text>
        {patientData?.socialWork === 'IOSEP' && (
          <Text style={[styleBody.watermarkText, { textTransform: 'none' }]}>
            {' '}
            - Copia fiel de la original.
          </Text>
        )}
      </Text>
      {patientData && (
        <View>
          <Text style={styleHeader.headerText}>
            <Text
              style={styleBody.textCapitalize}
            >{`${patientData?.name} (${patientData?.age} años) - DNI: ${patientData?.dni}`}</Text>
          </Text>
          <Text style={styleHeader.headerText}>
            <Text style={styleBody.textUppercase}>
              {patientData?.socialWork}
            </Text>
            {patientData?.affiliate && (
              <Text style={styleBody.textUppercase}>
                {' '}
                - {patientData?.affiliate}
              </Text>
            )}
          </Text>
        </View>
      )}

      <Image style={styleHeader.imageHeader} src={image} />
    </View>
  );
}
