import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfMedicine({ indication, index }) {
  return (
    <View style={styleBody.dataEvolution}>
      <Text style={indication.suspend && styleBody.textSuspend}>
        <Text style={styleBody.text}>{index}. </Text>
        <Text
          style={styleBody.text}
          render={() =>
            indication.suspend
              ? `SUSPENDIDA - ${indication.drug.name} - ${indication.dosis} ${indication.unity}`
              : `${indication.drug.name} - ${indication.dosis} ${indication.unity}`
          }
        ></Text>
        <Text
          style={styleBody.text}
          render={() =>
            indication.extra !== 'Unica Dosis' &&
            ` - Cada ${indication.time} hs`
          }
        />
        <Text
          style={styleBody.text}
          render={() => indication.hour && ` (${indication.hour})`}
        />
        <Text
          style={styleBody.text}
          render={() =>
            indication.extra !== 'Habitual' && `- ${indication.extra}`
          }
        />
        <Text
          style={styleBody.text}
          render={() =>
            indication.description && ` - ${indication.description}`
          }
        />

        <Text
          style={styleBody.text}
          render={() => indication.status.map((i) => i === true && ` \u2611`)}
        />
      </Text>
    </View>
  );
}
