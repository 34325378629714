import React, { useEffect, useState } from 'react';
import DyalisisTableControls from '../../../components/Infarmary/DyalisisTableControls';
import {
  Button,
  Card,
  DatePicker,
  PageHeader,
  Modal as ModalAntd,
  Tooltip,
  message,
  Badge,
} from 'antd';
import Modal from '../../../components/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DyalisisControlForm from '../../../components/Infarmary/DyalisisControlForm';
import { ViewInfo } from '../../../components/ListAndDetail';
import {
  deleteDyalisisControlApi,
  getDyalisisControlApi,
} from '../../../api/control';
import { getAccessTokenApi } from '../../../api/auth';
import Spin from '../../../components/Spin';
import locale from 'antd/es/date-picker/locale/es_ES';
import { getNoteApi } from '../../../api/note';
import {
  EyeOutlined,
  MedicineBoxOutlined,
  PrinterOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';
import { pdf, Document, Page } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  PdfHeader,
  PdfFooter,
  PdfDyalisisControl,
  PdfDyalisisDeliver,
  PdfTableDyalisisHeader,
  PdfTitle,
  PdfEvolution,
  PdfSignature,
} from '../../../components/PdfReports';
import { styleBody } from '../../../components/PdfReports/styles';
import { Link } from 'react-router-dom';
import { getHeparinaMedicineDyalisisApi } from '../../../api/medicine';
import { PdfDyalisisEvolution } from '../../../components/PdfReports/PdfDyalisisEvolution';
import { getOneEvolutionDyalisisApi } from '../../../api/evolution';
import { getSignatureApi } from '../../../api/user';

export default function DyalisisControls(props) {
  const { id, view, history } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [dataNotes, setDataNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment().utcOffset(-180).startOf('day'));
  const [viewNotes, setViewNotes] = useState(false);
  const [preVascular, setPreVascular] = useState();

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );
  const { organization, tenant } = useSelector((store) => store.tenantData);

  const user = useSelector((store) => store.userData?.user?._id);

  useEffect(() => {
    setLoading(true);

    let controls;

    const getData = async () => {
      const token = await getAccessTokenApi();
      await getDyalisisControlApi(id, token, month)
        .then((response) => {
          controls = response?.controls;
          setPreVascular(response?.preVascular);
        })
        .catch((err) => {});

      await getHeparinaMedicineDyalisisApi({
        token,
        income: income._id,
        month,
      }).then((response) => {
        controls.map((d, index) =>
          response?.data.find((r) => {
            if (
              r._id.day === d.day &&
              r._id.month === d.month &&
              r._id.year === d.year
            )
              return (controls[index] = {
                ...controls[index],
                cantHeparina: r.cant,
              });
          })
        );
      });

      setData(controls);

      setLoading(false);
      setReload(false);
    };
    getData();
  }, [id, reload, month]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();

      getNoteApi(id, 'desc', token)
        .then((response) => {
          setDataNotes(response.notes);
        })
        .catch((err) => {});
    };

    getData();
  }, [viewNotes]);

  var income = {};
  history
    ? incomesHistory.find((i) => {
        if (i._id === id) {
          income = i;
        }
      })
    : incomesActive.find((i) => {
        if (i._id === id) {
          income = i;
        }
      });

  const addControl = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregando nueva sesión a ${income.name}`);
    setModalContent(
      <DyalisisControlForm
        incomeId={id}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        discharge={income.dischargeDate}
        incomeType={income?.type}
        preVascular={preVascular}
      />
    );
  };

  const editControl = (hdNumber, data) => {
    setIsVisibleModal(true);
    setModalTitle(`Editando Hemodialisis Nº ${hdNumber}`);
    setModalContent(
      <DyalisisControlForm
        incomeId={id}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        discharge={income.dischargeDate}
        data={data}
      />
    );
  };

  const deleteControl = (hdNumber, data) => {
    ModalAntd.confirm({
      title: `Eliminar control Nº ${hdNumber}`,
      content: `¿ Estas seguro que quieres eliminar el control Nº ${hdNumber}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteDyalisisControlApi({ id: data._id, token, user })
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              setReload(true);
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
      },
    });
  };

  const changeOrder = async () => {
    setLoading(true);
    await data.reverse();
    setLoading(false);
  };

  const printDyalisisControls = async () => {
    data.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

    let token = await getAccessTokenApi();
    await getHeparinaMedicineDyalisisApi({
      token,
      income: income._id,
      month,
    }).then((response) => {
      data.map((d, index) =>
        response?.data.find((r) => {
          if (
            r._id.day === d.day &&
            r._id.month === d.month &&
            r._id.year === d.year
          )
            return (data[index] = { ...data[index], cantHeparina: r.cant });
        })
      );
    });

    let evolution;
    let signature;

    await getOneEvolutionDyalisisApi({ token, income: income._id, month }).then(
      async (response) => {
        evolution = response.evolution.length > 0 && response?.evolution[0];
        response.evolution.length > 0 &&
          response.evolution[0].user?.signature &&
          (await getSignatureApi(response.evolution[0].user.signature, token)
            .then((response) => {
              if (response?.status !== 200) {
                signature = null;
              } else {
                signature = response.url;
              }
            })
            .catch((err) => console.log(err)));
      }
    );

    const doc = (
      <Document>
        <Page style={styleBody.body}>
          <PdfHeader
            patientData={income}
            organization={organization}
            tenant={tenant}
          />
          {data?.map((data, index) => (
            <>
              <PdfDyalisisControl
                control={data}
                key={index}
                index={index + 1}
                income={income}
              />
            </>
          ))}

          <PdfFooter type={income.type} />
        </Page>
      </Document>
    );

    const docDeliver = (
      <Document>
        <Page style={styleBody.body}>
          <PdfHeader
            patientData={income}
            organization={organization}
            tenant={tenant}
            affiliate={income.affiliate}
            period={moment(month).format('MMMM')}
          />
          <PdfTitle title="Datos del paciente" />
          <PdfDyalisisEvolution income={income} />
          <PdfTitle title="Evolución" />
          <PdfEvolution data={evolution} />
          <PdfSignature
            signature={signature}
            name={evolution?.user?.name}
            lastname={evolution?.user?.lastname}
          />
          <PdfFooter type={income.type} />
        </Page>
        <Page style={styleBody.body} orientation="landscape">
          <PdfHeader
            patientData={income}
            organization={organization}
            tenant={tenant}
            affiliate={income.affiliate}
            period={moment(month).format('MMMM')}
          />
          <PdfTableDyalisisHeader />
          {data?.map((data, index) => (
            <>
              <PdfDyalisisDeliver
                control={data}
                key={index}
                index={index + 1}
                income={income}
              />
            </>
          ))}

          <PdfFooter type={income.type} />
        </Page>
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(docDeliver);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Protocolo Diálisis ${moment(month).format('MMMM')} - ${income.name}`
    );
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  if (loading) return <Spin />;

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`Cama: ${income?.bed?.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income?.bed?.name} ${income?.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income?.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income?.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            <>
              <DatePicker
                picker="month"
                locale={locale}
                placeholder="Fecha"
                allowClear={false}
                onChange={(value) => {
                  setMonth(moment(value));
                }}
                disabledDate={(current) =>
                  current && current > moment().endOf('day')
                }
                value={month}
              />
              <Tooltip title="Imprimir Protocolo">
                <Button
                  type="link"
                  onClick={() => {
                    printDyalisisControls();
                  }}
                  disabled={!data}
                >
                  <PrinterOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Insumos">
                <Link
                  to={
                    history
                      ? `/insumos/${income._id}/${true}`
                      : `/insumos/${income._id}`
                  }
                >
                  <Button type="link">
                    <PullRequestOutlined />
                  </Button>
                </Link>
              </Tooltip>
              <Tooltip title="Indicaciones">
                <Link
                  to={{
                    pathname: `/medicine/${income?._id}`,
                    hash: income.bed?.bedType,
                  }}
                >
                  <Button type="link">
                    <MedicineBoxOutlined />
                  </Button>
                </Link>
              </Tooltip>
              {view && (
                <Tooltip
                  title={
                    viewNotes
                      ? 'Ocultar notas de enfermería'
                      : 'Ver notas de enfermería'
                  }
                >
                  <Badge
                    count={
                      dataNotes.length > 0 &&
                      moment(dataNotes[0]?.date)
                        .startOf('day')
                        .isSame(moment().startOf('day'), 'day')
                        ? 1
                        : 0
                    }
                    size="small"
                  >
                    <Button
                      type="link"
                      onClick={() => setViewNotes(!viewNotes)}
                    >
                      <EyeOutlined />
                    </Button>
                  </Badge>
                </Tooltip>
              )}
              <Tooltip title="Debe terminar de completar datos de la ultima sesion para continuar o debe situarse en el mes actual">
                <Button
                  type="primary"
                  onClick={() => addControl(income)}
                  disabled={
                    moment(month).isBefore(moment().startOf('day')) ||
                    (data.length > 0 && !data[0]?.endParams && data[0]?.present)
                  }
                >
                  Nueva Sesión
                </Button>
              </Tooltip>
            </>
          }
        />
      </div>

      {viewNotes && (
        <Card
          title="Notas de enfermería"
          headStyle={{ textAlign: 'center' }}
          style={{
            borderColor: '#002a52',
            overflowY: 'scroll',
            height: '40vh',
          }}
        >
          {dataNotes?.length > 0 ? (
            <div className="view-info">
              {dataNotes.map((data) => (
                <ViewInfo data={data} />
              ))}
            </div>
          ) : (
            <span
              style={{
                textAlign: 'center',
                display: 'inherit',
                color: 'grey',
              }}
            >
              No existen notas
            </span>
          )}
        </Card>
      )}

      <DyalisisTableControls
        id={id}
        reload={reload}
        setReload={setReload}
        data={data}
        editControl={editControl}
        deleteControl={deleteControl}
        changeOrder={changeOrder}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'90%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
