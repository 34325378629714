import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React from 'react';
import { DocumentHistory } from './DocumentHistory';
import { getIncomeApi } from '../../api/income';
import { getAccessTokenApi } from '../../api/auth';
import { getNoteApi } from '../../api/note';
import { getEvolutionApi } from '../../api/evolution';
import { getMedicineApi } from '../../api/medicine';
import { getSignatureApi } from '../../api/user';
import { getControlApi, getDyalisisControlApi } from '../../api/control';
import { getOperationApi } from '../../api/operation';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export default function PdfHistoryDownload(props) {
  const { id, type } = props;
  const { organization, tenant } = useSelector((store) => store.tenantData);

  const getProps = async () => {
    try {
      const token = await getAccessTokenApi();

      // Obtener datos de ingreso
      const incomeResponse = await getIncomeApi(id, token);
      let epicrisisSignature = null;
      let doctorIncomeSignature = null;

      if (incomeResponse?.income?.userDoctorIncome?.signature) {
        const signatureIncomeResponse = await getSignatureApi(
          incomeResponse.income.userDoctorIncome.signature,
          token
        );

        doctorIncomeSignature =
          signatureIncomeResponse?.status === 200
            ? signatureIncomeResponse.url
            : null;
      }

      if (incomeResponse?.income?.userEpicrisis?.signature) {
        const signatureEpicrisisResponse = await getSignatureApi(
          incomeResponse.income.userEpicrisis.signature,
          token
        );

        epicrisisSignature =
          signatureEpicrisisResponse?.status === 200
            ? signatureEpicrisisResponse.url
            : null;
      }

      const incomeData = {
        income: incomeResponse?.income,
        doctorIncomeSignature,
        epicrisisSignature,
      };

      // Función para obtener firmas de cualquier colección
      const fetchSignatures = async (items) => {
        if (!items) return [];

        const signaturePromises = items.map(async (item) => {
          if (!item?.user?.signature) return null;

          try {
            const signatureResponse = await getSignatureApi(
              item.user.signature,
              token
            );
            return signatureResponse?.status === 200
              ? signatureResponse.url
              : null;
          } catch (error) {
            console.error('Error obteniendo firma:', error);
            return null;
          }
        });

        return await Promise.all(signaturePromises);
      };

      // Obtener evoluciones con firmas
      const evolutionResponse = await getEvolutionApi(id, 'asc', token);
      const evolutionSignatures = await fetchSignatures(
        evolutionResponse?.evolutions
      );

      const dataEvolutions = {
        evolutions: evolutionResponse?.evolutions,
        signatures: evolutionSignatures,
      };

      // Obtener medicamentos con firmas
      const medicineResponse = await getMedicineApi({
        id,
        order: 'asc',
        token,
      });
      const medicineSignatures = await fetchSignatures(
        medicineResponse?.medicines
      );

      const medicines = {
        medicines: medicineResponse?.medicines,
        signatures: medicineSignatures,
      };

      // Obtener operaciones con firmas
      const operationsResponse = await getOperationApi(id, 'asc', token);
      const operationsSignatures = await fetchSignatures(
        operationsResponse?.operations
      );

      const dataOperations = {
        operations: operationsResponse?.operations,
        signatures: operationsSignatures,
      };

      // Otras llamadas de datos
      const [dataNotes, controls] = await Promise.all([
        getNoteApi(id, 'asc', token).then((res) => res?.notes || []),
        type === '?Dialisis'
          ? getDyalisisControlApi(id, token).then(
              (res) => res?.controls?.reverse() || []
            )
          : getControlApi(id, token).then((res) => res?.controls || []),
      ]);

      return {
        incomeData,
        dataEvolutions,
        medicines,
        dataOperations,
        dataNotes,
        controls,
        organization,
        tenant,
        type,
      };
    } catch (error) {
      console.error('Error en getProps:', error);
      message.error('Error al generar documento. Intente nuevamente');
    }
  };

  return (
    <Button
      type="link"
      style={{
        paddingLeft: 0,
      }}
      size="middle"
      onClick={async () => {
        try {
          message.loading(
            'Generando documento. Por favor espere... Esta acción puede tardar...',
            0
          );
          const props = await getProps();

          const doc = <DocumentHistory {...props} />;
          const asPdf = pdf([]);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `${props.incomeData?.income.name}`);
          message
            .success('El documento se ha generado con exito.', 1)
            .then(() => message.destroy());
        } catch (err) {
          message
            .info(`${err}. En caso de aparecer este error, informar!`, 1)
            .then(() => message.destroy());
        }
      }}
    >
      Descargar PDF <DownloadOutlined />
    </Button>
  );
}
