import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import {
  PdfHeader,
  PdfEvolution,
  PdfTitle,
  PdfFooter,
  PdfMedicine,
  PdfInfusion,
  PdfInfarmaryAction,
  PdfSignature,
  PdfControl,
  PdfTableHeader,
  PageIncome,
  PdfOperation,
  PdfDyalisisControl,
} from './index';
import moment from 'moment';
import { styleBody } from './styles';

export function DocumentHistory(props) {
  const {
    dataEvolutions,
    dataNotes,
    medicines,
    dataOperations,
    controls,
    organization,
    tenant,
    type,
  } = props;
  const { income, doctorIncomeSignature, epicrisisSignature } =
    props.incomeData;

  return (
    <Document>
      <PageIncome
        income={income}
        title="Ingreso"
        organization={organization}
        tenant={tenant}
        signature={doctorIncomeSignature}
        user={income?.userDoctorIncome}
      />
      <Page style={styleBody.body}>
        <PdfHeader
          patientData={income}
          type={income.type}
          organization={organization}
          tenant={tenant}
        />
        <View>
          <PdfTitle title="Evoluciones" />
          {dataEvolutions.evolutions?.map((data, index) => (
            <View key={index}>
              <PdfEvolution data={data} />
              <PdfSignature
                signature={dataEvolutions?.signatures[index]}
                name={data?.user?.name}
                lastname={data?.user?.lastname}
                medicalSpeciality={data?.user?.medicalSpeciality}
                userType={data?.user?.type}
                mp={data?.user?.mp}
                licenseType={data?.user?.licenseType}
              />
            </View>
          ))}
        </View>
        <PdfFooter type={income.type} />
      </Page>
      <Page style={styleBody.body}>
        <PdfHeader
          patientData={income}
          organization={organization}
          tenant={tenant}
        />
        <PdfTitle title="Indicaciones Medicas" />
        {medicines?.medicines?.map((medicine, index) => (
          <View key={index}>
            <Text style={styleBody.evolutionTitle}>
              {moment(medicine.date).format('DD/MM/YYYY')}
            </Text>
            <Text
              style={styleBody.evolutionTitle}
              render={() => medicine?.indication?.length > 0 && 'Medicamentos'}
            />
            {medicine.indication?.map((indication, index) => (
              <PdfMedicine indication={indication} index={index + 1} />
            ))}
            <Text
              style={styleBody.evolutionTitle}
              render={() => medicine?.infusion?.length > 0 && 'Infusiones'}
            />
            {medicine.infusion?.map((infusion, index) => (
              <PdfInfusion infusion={infusion} index={index + 1} />
            ))}
            <Text
              style={styleBody.evolutionTitle}
              render={() =>
                medicine?.action?.length > 0 && 'Acciones de enfermería'
              }
            />
            {medicine.action?.map((action, index) => (
              <PdfInfarmaryAction action={action} index={index + 1} />
            ))}
            <PdfSignature
              signature={medicines?.signatures[index]}
              name={medicine?.user.name}
              lastname={medicine?.user?.lastname}
              medicalSpeciality={medicine?.user?.medicalSpeciality}
              userType={medicine?.user?.type}
              mp={medicine?.user?.mp}
              licenseType={medicine?.user?.licenseType}
            />
          </View>
        ))}
        <PdfFooter type={income.type} />
      </Page>
      <Page style={styleBody.body}>
        <PdfHeader
          patientData={income}
          organization={organization}
          tenant={tenant}
        />
        <PdfTitle title="Notas de Enfermeria" />
        {dataNotes?.map((data, index) => (
          <View key={index}>
            <PdfEvolution data={data} />
            <PdfSignature
              name={data?.user?.name}
              lastname={data?.user?.lastname}
            />
          </View>
        ))}
        <PdfFooter type={income.type} />
      </Page>
      <Page style={styleBody.body}>
        <PdfHeader
          patientData={income}
          organization={organization}
          tenant={tenant}
        />
        <PdfTitle
          title={
            type === '?Dialisis' ? 'Controles Diálisis' : 'Controles Frecuentes'
          }
        />
        {type !== '?Dialisis' && <PdfTableHeader />}
        {controls?.map((control, index) =>
          type === '?Dialisis' ? (
            <PdfDyalisisControl control={control} index={index + 1} />
          ) : (
            <PdfControl control={control} />
          )
        )}
        <PdfFooter type={income.type} />
      </Page>
      {dataOperations?.operations?.length > 0 && (
        <Page style={styleBody.body}>
          <PdfHeader
            patientData={income}
            organization={organization}
            tenant={tenant}
          />
          <PdfTitle title="Parte de Quirofano" />
          {dataOperations?.operations?.map((data, index) => (
            <View key={index}>
              <PdfOperation
                operation={data}
                key={index}
                index={index + 1}
                income={income}
                medicalSpeciality={data?.user?.medicalSpeciality}
                userType={data?.user?.type}
                mp={data?.user?.mp}
                licenseType={data?.user?.licenseType}
              />
              {data?.user?.type === 'Medico' && (
                <PdfSignature
                  signature={dataOperations?.signatures[index]}
                  name={data?.user?.name}
                  lastname={data?.user?.lastname}
                  medicalSpeciality={data?.user?.medicalSpeciality}
                  userType={data?.user?.type}
                  mp={data?.user?.mp}
                  licenseType={data?.user?.licenseType}
                />
              )}
            </View>
          ))}
          <PdfFooter type={income.type} />
        </Page>
      )}
      {income.epicrisis && (
        <PageIncome
          income={income}
          title="Epicrisis"
          organization={organization}
          tenant={tenant}
          signature={epicrisisSignature}
          user={income?.userEpicrisis}
        />
      )}
    </Document>
  );
}
