import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Col,
  Row,
  message,
} from 'antd';
import { useState } from 'react';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  vascularAccess,
  vascularAccessLocations,
} from '../../../utils/constants';
import {
  addVascularAccessApi,
  updateVascularAccessApi,
} from '../../../api/vascularAccess';
import { getAccessTokenApi } from '../../../api/auth';
import moment from 'moment';

const { Option } = Select;

const VascularAccessForm = ({
  incomeId,
  setIsVisibleModal,
  setReload,
  data,
}) => {
  const [loading, setLoading] = useState(false);

  const [vascularAccessForm] = Form.useForm();

  useEffect(() => {
    data &&
      vascularAccessForm.setFieldsValue({
        date: moment(data.date),
        status: data.status,
        type: data.type,
        location: data.location,
        notes: data.notes,
      });
  }, [incomeId, data]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const token = await getAccessTokenApi();
      const response = data
        ? await updateVascularAccessApi({
            token,
            id: data.id,
            data: values,
          })
        : await addVascularAccessApi({
            token,
            incomeId,
            data: values,
          });

      if (response?.code === 200) {
        message.success(response.message);
        setIsVisibleModal(false);
        setReload(true);
        vascularAccessForm.resetFields();
      } else {
        message.error(response.message);
      }
    } catch (err) {
      message.error('Error del servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ status: 'Activo' }}
      form={vascularAccessForm}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Fecha de confección"
            name="date"
            rules={[
              { required: true, message: 'Por favor seleccione una fecha' },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              locale={locale}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Estado"
            name="status"
            rules={[
              { required: true, message: 'Por favor seleccione el estado' },
            ]}
          >
            <Select placeholder="Seleccione el estado">
              <Option value="Activo">Activo</Option>
              <Option value="Inactivo">Inactivo</Option>
              <Option value="Fallido">Fallido</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Tipo de acceso"
            name="type"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione el tipo de acceso',
              },
            ]}
          >
            <Select
              placeholder="Seleccione un tipo"
              optionFilterProp="children"
              inputMode="none"
            >
              {vascularAccess?.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="location"
            label="Ubicación"
            rules={[
              {
                required: true,
                message: 'Seleccione la ubicación del acceso vascular',
              },
            ]}
          >
            <Select placeholder="Seleccione una ubicación">
              {vascularAccessLocations.map((location) => (
                <Select.Option key={location} value={location}>
                  {location}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Form.Item label="Última revisión" name="lastRevision">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item> */}
        <Col xs={24}>
          <Form.Item label="Notas" name="notes">
            <Input.TextArea rows={6} placeholder="Notas adicionales" />
          </Form.Item>
        </Col>
        {/* <Form.List name="complications">
          {(fields, { add, remove }) => (
            <>
              <Col xs={24}>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row gutter={[24, 24]}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'date']}
                        fieldKey={[fieldKey, 'date']}
                        rules={[
                          {
                            required: true,
                            message: 'Por favor seleccione la fecha',
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Fecha"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={15}>
                      <Form.Item
                        {...restField}
                        name={[name, 'description']}
                        fieldKey={[fieldKey, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese la descripción',
                          },
                        ]}
                      >
                        <Input placeholder="Descripción" />
                      </Form.Item>
                    </Col>
                    <Button type="link" onClick={() => remove(name)}>
                      <MinusCircleOutlined />
                    </Button>
                  </Row>
                ))}
              </Col>
              <Button type="dashed" onClick={() => add()}>
                Agregar complicación
              </Button>
            </>
          )}
        </Form.List> */}
        <Col xs={24}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: '100%' }}
            >
              {data ? 'Actualizar acceso vascular' : 'Guardar acceso vascular'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default VascularAccessForm;
