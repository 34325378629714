import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Tooltip,
  Button,
  Divider,
  Modal,
  notification,
  message,
} from 'antd';
import moment from 'moment';
import { ROLES } from '../../utils/constants';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Spin from '../../components/Spin';
import { deleteOperationApi, getOperationApi } from '../../api/operation';
import { getAccessTokenApi } from '../../api/auth';
import {
  ListDates,
  PageListAndView,
  ViewOperation,
} from '../../components/ListAndDetail';
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from '@ant-design/icons';

import { pdf, Document, Page } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  PdfHeader,
  PdfTitle,
  PdfOperation,
  PdfFooter,
} from '../../components/PdfReports';
import { styleBody } from '../../components/PdfReports/styles';

export default function Operation(props) {
  const { id } = props.match.params;
  const { path } = props.match;

  const [operations, setOperations] = useState();
  const [order, setOrder] = useState('desc');
  const [data, setData] = useState();
  const [dataAll, setDataAll] = useState();
  const [reload, setReload] = useState(false);
  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );

  const { organization, tenant } = useSelector((store) => store.tenantData);

  let income;

  if (path === '/operating/:id') {
    incomesActive.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  } else {
    incomesHistory.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  }

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getOperationApi(id, order, token)
        .then((response) => {
          setOperations(response.operations);
          response.operations.length > 0 &&
            setData({
              _id: response.operations[0]._id,
              user: response.operations[0].user,
              date: response.operations[0].date,
              hourStart: response.operations[0].hourStart,
              hourFinish: response.operations[0].hourFinish,
              surgeon: response.operations[0].surgeon,
              mpSurgeon: response.operations[0].mpSurgeon,
              helpers: response.operations[0].helpers,
              anesthetist: response.operations[0].anesthetist,
              typeAnethetist: response.operations[0].typeAnethetist,
              mpAnesthetist: response.operations[0].mpAnesthetist,
              instrumenter: response.operations[0].instrumenter,
              transfusion: response.operations[0].transfusion,
              pathological: response.operations[0].pathological,
              principalDiagnostic: response.operations[0].principalDiagnostic,
              operationDiagnostic: response.operations[0].operationDiagnostic,
              arc: response.operations[0].arc,
              description: response.operations[0].description,
              operationPractice: response.operations[0]?.operationPractice,
            });
        })
        .catch((err) => {});
    };
    getData();

    setReload(false);
  }, [id, order, reload]);

  if (!income || reload) {
    return <Spin />;
  }

  const viewOperation = (operation) => {
    setData({
      _id: operation._id,
      user: operation.user,
      date: operation.date,
      hourStart: operation.hourStart,
      hourFinish: operation.hourFinish,
      surgeon: operation.surgeon,
      mpSurgeon: operation.mpSurgeon,
      helpers: operation.helpers,
      anesthetist: operation.anesthetist,
      typeAnethetist: operation.typeAnethetist,
      mpAnesthetist: operation.mpAnesthetist,
      instrumenter: operation.instrumenter,
      transfusion: operation.transfusion,
      pathological: operation.pathological,
      principalDiagnostic: operation.principalDiagnostic,
      operationDiagnostic: operation.operationDiagnostic,
      arc: operation.arc,
      description: operation.description,
      operationPractice: operation?.operationPractice,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const viewAll = () => {
    const data = [];

    operations &&
      operations.map((operation) =>
        data.push({
          user: operation.user,
          date: operation.date,
          hourStart: operation.hourStart,
          hourFinish: operation.hourFinish,
          surgeon: operation.surgeon,
          mpSurgeon: operation.mpSurgeon,
          helpers: operation.helpers,
          anesthetist: operation.anesthetist,
          typeAnethetist: operation.typeAnethetist,
          mpAnesthetist: operation.mpAnesthetist,
          instrumenter: operation.instrumenter,
          transfusion: operation.transfusion,
          pathological: operation.pathological,
          principalDiagnostic: operation.principalDiagnostic,
          operationDiagnostic: operation.operationDiagnostic,
          arc: operation.arc,
          description: operation.description,
          operationPractice: operation?.operationPractice,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const printOperation = async () => {
    const doc = (
      <Document>
        <Page style={styleBody.body}>
          <PdfHeader
            patientData={income}
            organization={organization}
            tenant={tenant}
          />
          <PdfTitle title="Parte de Quirofano" />
          {data !== null ? (
            <PdfOperation operation={data} key={1} index={1} income={income} />
          ) : (
            dataAll?.map((data, index) => (
              <PdfOperation
                operation={data}
                key={index}
                index={index + 1}
                income={income}
              />
            ))
          )}
          <PdfFooter type={income.type} />
        </Page>
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Parte Quirofano - ${income.name}`);
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${income?.bed?.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income?.bed?.name} ${income?.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income?.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income?.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            <>
              <Tooltip title="Imprimir Parte Quirofano">
                <Button
                  type="link"
                  onClick={() => {
                    printOperation();
                  }}
                  disabled={!data && !dataAll}
                >
                  <PrinterOutlined />
                </Button>
              </Tooltip>
              <PermissionsAsigner
                requiredRole={ROLES.SECRETARY}
                visible={ROLES.EMPLOYEE}
              >
                <Link
                  to={{
                    pathname: `/new-operation/${id}/${income?.name}/${income?.bed?.name}/${income?.type}`,
                    state: { operation: data },
                  }}
                >
                  <Button type="primary">Nuevo Parte</Button>
                </Link>
              </PermissionsAsigner>
            </>
          }
        />
      </div>
      <PageListAndView
        datesTitle="Operaciones"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        componentDate={<ListDates data={operations} viewData={viewOperation} />}
        detailTitle="Parte Quirofano"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              {dataAll?.map((data, index) => (
                <>
                  <ViewOperation data={data} income={income} />
                  <Divider />
                </>
              ))}
            </div>
          )
        }
        componentDetail={<ViewOperation data={data} income={income} />}
        extra={
          <EditOperation data={data} income={income} setReload={setReload} />
        }
      />
    </div>
  );
}

function EditOperation(props) {
  const { income, data, setReload } = props;

  const deleteOperation = () => {
    Modal.confirm({
      title: 'Eliminar parte quirofano',
      content: `¿ Estas seguro que quieres eliminar el parte del ${moment(
        data.date
      ).format('DD-MM HH:mm')}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        const token = await getAccessTokenApi();
        deleteOperationApi(data._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  return (
    <>
      <Link
        to={{
          pathname: `/edit-operation/${data?._id}/${income?.name}/${income?.bed?.name}/${income?.type}`,
          operation: data,
        }}
      >
        <Tooltip title="Editar">
          <Button type="link" disabled={!data}>
            <EditOutlined />
          </Button>
        </Tooltip>
      </Link>
      <PermissionsAsigner requiredRole={ROLES.OWNER} visible={ROLES.SECRETARY}>
        <Tooltip title="Eliminar">
          <Button
            type="link"
            onClick={() => deleteOperation()}
            disabled={!data}
          >
            <DeleteOutlined style={data && { color: 'red' }} />
          </Button>
        </Tooltip>
      </PermissionsAsigner>
    </>
  );
}
