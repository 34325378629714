import React, { useState } from 'react';
import { Table, Button, Tooltip, Menu, Dropdown, Alert, Row, Col } from 'antd';
import {
  EditOutlined,
  CheckOutlined,
  CommentOutlined,
  SnippetsOutlined,
  EyeOutlined,
  AuditOutlined,
  LoadingOutlined,
  PlusSquareOutlined,
  MoreOutlined,
  FilePdfOutlined,
  MedicineBoxOutlined,
  SwapOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Modal from '../Modal';
import DischargeForm from '../DischargeForm';
import { Link } from 'react-router-dom';
import NewsForm from '../NewsForm';
import PermissionsAsigner from '../PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import ChangeFloorForm from '../ChangeFloorForm';
import './IncomeTable.scss';
import { onIncomeValueChange } from '../../actions';

export default function IncomeTable(props) {
  const { search, today, incomesActive, incomeType, action } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const { loadingActive, page } = useSelector((store) => store.incomeData);

  const { floors } = useSelector((store) => store.tenantData);

  let floorType = floors?.find(
    (floor) => floor.id === incomeType && floor?.type
  );

  const dispatch = useDispatch();

  const data = [];
  if (incomesActive) {
    if (search) {
      const incomesFilters = incomesActive.filter(
        (income) =>
          income?.name
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()) ||
          income?.bed?.name
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()) ||
          income?.socialWork
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()) ||
          income?.dni.toString().trim().includes(search.toLowerCase().trim())
      );
      incomesFilters.map((income, index) => {
        data.push({
          key: index,
          _id: income._id,
          date: income.date,
          bed: income.bed,
          name: income.name,
          dni: income.dni,
          age: income.age,
          weigth: income.weigth,
          heigth: income.heigth,
          imc: income.imc,
          sex: income.sex,
          socialWork: income.socialWork,
          affiliate: income.affiliate,
          city: income.city,
          doctor: income.doctor,
          origin: income.origin,
          queryReason: income.queryReason,
          diagnostic: income.diagnostic,
          news: income.news,
          epicrisis: income.epicrisis,
          lastNewsHour: income.lastNewsHour,
          lastEvolutionHour: income.lastEvolutionHour,
          lastMedicineHour: income.lastMedicineHour,
          pdfReports: income.pdfReports,
        });
      });
    } else if (today) {
      const incomesFilters = incomesActive.filter((income) => {
        return moment(income.date)
          .startOf('day')
          .isSame(moment().startOf('day'), 'day');
      });
      incomesFilters.map((income, index) => {
        data.push({
          key: index,
          _id: income._id,
          date: income.date,
          bed: income.bed,
          name: income.name,
          dni: income.dni,
          age: income.age,
          weigth: income.weigth,
          heigth: income.heigth,
          imc: income.imc,
          sex: income.sex,
          socialWork: income.socialWork,
          affiliate: income.affiliate,
          city: income.city,
          doctor: income.doctor,
          origin: income.origin,
          queryReason: income.queryReason,
          diagnostic: income.diagnostic,
          news: income.news,
          epicrisis: income.epicrisis,
          lastNewsHour: income.lastNewsHour,
          lastEvolutionHour: income.lastEvolutionHour,
          lastMedicineHour: income.lastMedicineHour,
          pdfReports: income.pdfReports,
        });
      });
    }
  }

  const columns = [
    {
      title: 'Cama',
      width: 50,
      dataIndex: 'bed',
      key: 'bed',
      render: (bed) => <span>{bed?.name}</span>,
    },
    {
      title: 'Paciente',
      width: 180,
      key: 'name',
      render: (income) =>
        income?.bed?.bedType !== 'Dialisis' ? (
          <Tooltip title="Informe internación" placement="right">
            <Link
              to={{
                pathname: `/report/${income?._id}`,
                search:
                  income.bed?.bedType === 'Dialisis' &&
                  `?${income.bed?.bedType}`,
              }}
            >
              <Button
                type="text"
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'initial',
                  height: 'auto',
                  textTransform: 'capitalize',
                }}
              >
                {income.name}
              </Button>
            </Link>
          </Tooltip>
        ) : (
          <span
            style={{
              whiteSpace: 'normal',
              textAlign: 'initial',
              height: 'auto',
              textTransform: 'capitalize',
            }}
          >
            {income.name}
          </span>
        ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: (incomeType === 'all' || floorType) && 'ascend',
    },
    {
      title: 'Fecha de ingreso',
      width: 50,
      dataIndex: 'date',
      responsive: ['md'],
      key: 'date',
      render: (record) => <span>{moment(record).format('DD/MM/YY')}</span>,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    floorType?.type !== 'Dialisis'
      ? {
          title: 'Diagnostico presuntivo',
          width: 150,
          dataIndex: 'diagnostic',
          key: 'diagnostic',
        }
      : {
          title: 'Etiología de la IRC',
          width: 150,
          dataIndex: 'queryReason',
          key: 'queryReason',
        },
    {
      title: 'Obra social',
      className: 'text-uppercase',
      width: 120,
      dataIndex: 'socialWork',
      key: 'socialWork',
      sorter: (a, b) => a.socialWork.localeCompare(b.socialWork),
    },
    {
      title: 'HOY',
      width: 10,
      responsive: ['lg'],
      render: (income) => (
        <>
          <Row gutter={24}>
            <Col span={6}>
              <Tooltip
                title={
                  income?.lastNewsHour
                    ? `Ult. Novedad: ${moment(income.lastNewsHour).fromNow()}`
                    : 'No tiene novedades'
                }
              >
                <Alert
                  type={
                    moment().isSame(income.lastNewsHour, 'day') &&
                    income?.lastNewsHour
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  banner
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip
                title={
                  income?.lastEvolutionHour
                    ? `Ult. Evolución: ${moment(
                        income.lastEvolutionHour
                      ).fromNow()}`
                    : 'No tiene evoluciones'
                }
              >
                <Alert
                  type={
                    (floorType?.type !== 'Dialisis'
                      ? moment().isSame(income.lastEvolutionHour, 'day')
                      : moment().isSame(income.lastEvolutionHour, 'month')) &&
                    income?.lastEvolutionHour
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  banner
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Tooltip
                title={
                  income?.lastMedicineHour
                    ? `Ult. Indicación: ${moment(
                        income.lastMedicineHour
                      ).fromNow()}`
                    : 'No tiene indicaciones'
                }
              >
                <Alert
                  type={
                    moment().isSame(income?.lastMedicineHour, 'day') &&
                    income?.lastMedicineHour
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  banner
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            </Col>
            {income?.bed?.bedType !== 'Dialisis' ? (
              <Col span={6}>
                <Tooltip
                  title={
                    income?.pdfReports.some(
                      (pdf) =>
                        pdf.type === 'Laboratorio' &&
                        moment().isSame(pdf.date, 'day')
                    )
                      ? `Se cargo un laboratorio hoy`
                      : 'No tiene laboratorios hoy'
                  }
                >
                  <Alert
                    type={
                      income?.pdfReports.some(
                        (pdf) =>
                          pdf.type === 'Laboratorio' &&
                          moment().isSame(pdf.date, 'day')
                      )
                        ? 'success'
                        : 'error'
                    }
                    showIcon
                    banner
                    style={{ padding: 0, paddingTop: 5 }}
                  />
                </Tooltip>
              </Col>
            ) : (
              <Col span={6}>
                <Tooltip
                  title={
                    income?.lastDyalisisControlHour ? (
                      <span>
                        Ult. Control:{' '}
                        {moment(income?.lastDyalisisControlHour).fromNow()}
                      </span>
                    ) : (
                      <span>Sin Controles</span>
                    )
                  }
                >
                  <Alert
                    type={
                      moment().isSame(income?.lastDyalisisControlHour, 'day') &&
                      income?.lastDyalisisControlHour
                        ? 'success'
                        : 'error'
                    }
                    showIcon
                    banner
                    style={{ padding: 0, paddingTop: 5 }}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        </>
      ),
    },
    {
      title: 'Acciones',
      key: 'detalle',
      width: 150,
      align: 'center',
      render: (income) => (
        <>
          <Row gutter={24}>
            <Tooltip title="Ver Ingreso">
              <Link
                to={{
                  pathname: `/view-income/${income?._id}`,
                  hash: income.bed?.bedType,
                }}
              >
                <Button type="link">
                  <EyeOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Evoluciones">
              <Link
                to={{
                  pathname: `/evolution/${income?._id}`,
                  hash: income.bed?.bedType,
                }}
              >
                <Button type="link">
                  <AuditOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Informes">
              <Link to={`/pdf-report/${income?._id}`}>
                <Button type="link">
                  <FilePdfOutlined />
                </Button>
              </Link>
            </Tooltip>
          </Row>
          <Row gutter={24}>
            <Tooltip title="Indicaciones">
              <Link
                to={{
                  pathname: `/medicine/${income?._id}`,
                  hash: income.bed?.bedType,
                }}
              >
                <Button type="link">
                  <MedicineBoxOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Enfermería">
              <Link
                to={
                  income.bed?.bedType !== 'Dialisis'
                    ? `/view-infarmary/${income?._id}`
                    : `/dialisis-controls/${income._id}/view`
                }
              >
                <Button
                  type="link"
                  style={{
                    color: '#8e0d1a',
                  }}
                >
                  <PlusSquareOutlined />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="Otros">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu mode="inline">
                    <Menu.Item>
                      <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
                        <Link to={`/edit-income/${income?._id}/${incomeType}`}>
                          <Button type="link" style={{ color: '#002a52' }}>
                            <span>Editar Ingreso</span> <EditOutlined />
                          </Button>
                        </Link>
                      </PermissionsAsigner>
                    </Menu.Item>
                    {floorType?.type === 'Dialisis' && (
                      <Menu.Item>
                        <Link to={`/vascular-access/${income?._id}`}>
                          <Button type="link" style={{ color: '#002a52' }}>
                            <span>Acceso vascular</span> <FileDoneOutlined />
                          </Button>
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <PermissionsAsigner requiredRole={ROLES.SECRETARY}>
                        <Button
                          type="link"
                          style={{ color: '#002a52' }}
                          onClick={() => changeFloorModal(income)}
                        >
                          <span>Cambio Piso</span> <SwapOutlined />
                        </Button>
                      </PermissionsAsigner>
                    </Menu.Item>
                    <Menu.Item>
                      <PermissionsAsigner requiredRole={ROLES.ADMIN}>
                        <Button
                          type="link"
                          style={{ color: '#002a52' }}
                          onClick={() => newsIncomeModal(income)}
                        >
                          <span>Novedad</span> <CommentOutlined />
                        </Button>
                      </PermissionsAsigner>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to={`/operating/${income?._id}`}>
                        <Button type="link" style={{ color: '#002a52' }}>
                          <span>Parte quirofano</span> <SnippetsOutlined />
                        </Button>
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <PermissionsAsigner requiredRole={ROLES.ADMIN}>
                        <Link to={`/add-epicrisis/${income?._id}`}>
                          <Button type="link" style={{ color: '#002a52' }}>
                            <span>Epicrisis</span> <FileDoneOutlined />
                          </Button>
                        </Link>
                      </PermissionsAsigner>
                    </Menu.Item>

                    <Menu.Item>
                      <PermissionsAsigner requiredRole={ROLES.ADMIN}>
                        <Button
                          type="link"
                          onClick={() => dischargeIncomeModal(income)}
                          style={{
                            color: '#5cb85c',
                          }}
                        >
                          <span>Dar alta</span> <CheckOutlined />
                        </Button>
                      </PermissionsAsigner>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="link">
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];

  const dischargeIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Dar de alta a ${income.name}`);
    setModalContent(
      <DischargeForm
        income={income}
        setIsVisibleModal={setIsVisibleModal}
        incomeType={incomeType}
      />
    );
  };

  const newsIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar novedad a ${income.name}`);
    setModalContent(
      <NewsForm
        income={income}
        setIsVisibleModal={setIsVisibleModal}
        incomeType={incomeType}
      />
    );
  };

  const changeFloorModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Cambiar de piso a ${income.name}`);
    setModalContent(
      <ChangeFloorForm
        income={income}
        setIsVisibleModal={setIsVisibleModal}
        incomeType={incomeType}
      />
    );
  };

  return (
    <div className="table">
      <Table
        columns={columns}
        dataSource={search || today ? data : incomesActive}
        scroll={{ x: 500 }}
        pagination={{
          pageSize: 20,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} pacientes`,
          current: page,
          onChange: (page) => {
            dispatch(onIncomeValueChange({ page }));
          },
        }}
        loading={
          loadingActive &&
          action === 'PUSH' && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
        locale={{ emptyText: 'No hay pacientes internados' }}
      />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
