import { basePath, apiVersion } from './config';

export async function getVascularAccessApi(id, order, token) {
  const url = `${basePath}/${apiVersion}/vascular-access/${id}&${order}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function addVascularAccessApi({ data, incomeId, token }) {
  const url = `${basePath}/${apiVersion}/add-vascular-access/${incomeId}`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err;
  }
}

export async function updateVascularAccessApi({ id, data, token }) {
  const url = `${basePath}/${apiVersion}/update-vascular-access/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err;
  }
}

export async function deleteVascularAccessApi(id, token) {
  const url = `${basePath}/${apiVersion}/delete-vascular-access/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}
