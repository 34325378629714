import React, { useEffect, useState } from 'react';
import './IncomeView.scss';
import EpicrisisForm from '../EpicrisisForm';
import { getIncomeApi } from '../../api/income';
import { notification, Button, Row, Col } from 'antd';
import Spin from '../Spin';
import { CommentOutlined, AuditOutlined } from '@ant-design/icons';
import Modal from '../Modal';
import PermissionsAsigner from '../PermissionsAsigner';
import NewsForm from '../NewsForm';
import { ROLES } from '../../utils/constants';
import { Link } from 'react-router-dom';
import IncomeData from './IncomeData';
import IncomeEpicrisis from './IncomeEpicrisis';
import { getAccessTokenApi } from '../../api/auth';
import PdfDownload from '../PdfReports/PdfDownload';
import { getSignatureApi } from '../../api/user';
import BackButton from '../BackButton';

export default function IncomeView(props) {
  const { id } = props.match.params;
  const { path } = props.match;
  const [income, setIncome] = useState();
  const [epicrisisSignature, setEpicrisisSignature] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getIncomeApi(id, token)
        .then(async (response) => {
          setIncome(response.income);
          if (response.income.dischargeDate) {
            response.income.userEpicrisis.signature &&
              (await getSignatureApi(
                response.income.userEpicrisis.signature,
                token
              )
                .then((response) => {
                  if (response?.status !== 200) {
                    setEpicrisisSignature(null);
                  } else {
                    setEpicrisisSignature(response.url);
                  }
                })
                .catch((err) => console.log(err)));
          }
        })
        .catch((err) => {
          notification['warning']({
            message: 'Error del servidor, vuelva al historial.',
          });
        });
    };

    getData();
  }, [id, isVisibleModal]);

  const newsIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar novedad a ${income.name}`);
    setModalContent(
      <NewsForm income={income} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  if (!income) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <div className="header">
          <BackButton />
          {path === '/view-income-history/:id' ? (
            <PdfDownload
              income={income}
              title="Epicrisis"
              signature={epicrisisSignature}
            />
          ) : (
            path !== '/add-epicrisis/:id' && (
              <PdfDownload income={income} title="Ingreso" />
            )
          )}
          {!income.dischargeDate && (
            <h6 style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
              Ingreso realizado por: {income.user}
            </h6>
          )}
          <h6 style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
            Médico: {income?.userDoctorIncome?.name}{' '}
            {income?.userDoctorIncome?.lastname}
          </h6>
          {!income.dischargeDate && (
            <h6 style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
              Ultima novedad por: {income.userNews}
            </h6>
          )}
          <h6 style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
            Teléfono de contacto: {income.contactPhone}
          </h6>
        </div>

        <table
          cellSpacing="1"
          cellPadding="3"
          border="1px"
          bgcolor="#fff"
          className="income-view-table"
        >
          <div>
            <IncomeData income={income} path={path} />
            {(path === '/add-epicrisis/:id' ||
              path === '/add-epicrisis-history/:id') && (
              <p className="income-view">
                <h6>Epicrisis: </h6>
                <EpicrisisForm income={income} path={path} />
              </p>
            )}
            {path === '/view-income-history/:id' && (
              <IncomeEpicrisis income={income} signature={epicrisisSignature} />
            )}
          </div>
          {path === '/view-income/:id' && income.dischargeReason === null && (
            <div style={{ textAlign: 'center', margin: '5%' }}>
              <Row lg={24}>
                <PermissionsAsigner requiredRole={ROLES.ADMIN}>
                  <Col lg={12}>
                    <Button
                      style={{ width: '95%', marginBottom: '2%' }}
                      type="default"
                      onClick={() => newsIncomeModal(income)}
                    >
                      Agregar novedad
                      <CommentOutlined />
                    </Button>
                  </Col>
                </PermissionsAsigner>
                <Col lg={12}>
                  <Link
                    to={{
                      pathname: `/evolution/${income?._id}`,
                      hash: props.location.hash,
                    }}
                  >
                    <Button type="default" style={{ width: '95%' }}>
                      Evoluciones
                      <AuditOutlined />
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          )}
        </table>
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </>
  );
}
