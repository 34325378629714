export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const ROLES = {
  OWNER: { value: 2, name: 'Acceso completo', roleId: 'Medico' },
  ADMIN: { value: 1, name: 'Medico Completo', roleId: 'Medico' },
  SECRETARY: {
    value: 0,
    name: 'Administrativo',
    roleId: 'Administrativo',
  },
  ADMININFARMARY: {
    value: -1,
    name: 'Admin. Enfermería',
    roleId: 'Enfermero',
  },
  INFARMARY: { value: -2, name: 'Enfermería', roleId: 'Enfermero' },
  VIEW: { value: -3, name: 'Medico Ambulatorio', roleId: 'Medico' },
  EMPLOYEE: {
    value: -4,
    name: 'Interconsulta',
    roleId: 'Medico',
  },
};

export const units = [
  { name: 'mg' },
  { name: 'gr' },
  { name: 'ml' },
  { name: 'mug' },
  { name: 'unidad' },
  { name: 'puff' },
  { name: 'ampolla' },
  { name: 'gotas' },
  { name: 'comprimido' },
  { name: 'frasco' },
];

export const dischargeReasons = [
  { name: 'Alta Voluntaria' },
  { name: 'Alta Médica' },
  { name: 'Domicilio' },
  { name: 'Otra Institución' },
  { name: 'Obito' },
];

export const dischargeDyalisisReasons = [
  { name: 'Derivación' },
  { name: 'Transplante' },
  { name: 'Recuperación de función renal' },
  { name: 'Óbito' },
];

export const queryDyalisisReasons = [
  'Amiloidosis',
  'Causa desconocida',
  'Nefropatía diabética',
  'Glomerulonefritis',
  'Nefritis tubulointersticial',
  'Nefritis lúpica',
  'Nefroangioesclerosis',
  'Nefropatía obstructiva',
  'Mieloma múltiple',
  'Poliquistosis renal',
  'Rechazo de transplante renal',
  'Otras',
];

export const origin = [
  { name: 'Consultorio' },
  { name: 'Guardia / Urgencia' },
  { name: 'Internación programada' },
  { name: 'Otra institucion' },
];

export const vascularAccess = [
  { name: 'Fístula' },
  { name: 'Prótesis' },
  { name: 'Catéter permanente' },
  { name: 'Catéter transitorio' },
];

export const vascularAccessLocations = [
  'Fístula arteriovenosa (brazo derecho)',
  'Fístula arteriovenosa (brazo izquierdo)',
  'Fístula arteriovenosa (antebrazo derecho)',
  'Fístula arteriovenosa (antebrazo izquierdo)',
  'Fístula arteriovenosa (muñeca derecha)',
  'Fístula arteriovenosa (muñeca izquierda)',
  'Prótesis vascular (brazo derecho)',
  'Prótesis vascular (brazo izquierdo)',
  'Prótesis vascular (antebrazo derecho)',
  'Prótesis vascular (antebrazo izquierdo)',
  'Catéter venoso central (yugular derecha)',
  'Catéter venoso central (yugular izquierda)',
  'Catéter venoso central (subclavia derecha)',
  'Catéter venoso central (subclavia izquierda)',
  'Catéter venoso central (femoral derecha)',
  'Catéter venoso central (femoral izquierda)',
  'Catéter peritoneal',
  'Otro',
];

export const incomesInfarmary = [
  { name: 'D/A 5%' },
  { name: 'D/A 10%' },
  { name: 'D/A 25%' },
  { name: 'D/A 50%' },
  { name: 'SEB' },
  { name: 'S/F 0,9%' },
  { name: 'MANITOL' },
  { name: 'RINGER L.' },
  { name: 'SANGRE' },
  { name: 'PLASMA' },
  { name: 'OTROS' },
  { name: 'ORAL' },
  { name: 'SNG/SNY' },
  { name: 'YEYUNOSTOM' },
];

export const feriados = [
  {
    mes: '1',
    dia: 1,
    motivo: 'Año nuevo',
  },
  {
    mes: '3',
    dia: 3,
    motivo: 'Carnaval',
  },
  {
    mes: '3',
    dia: 4,
    motivo: 'Carnaval',
  },
  {
    mes: '3',
    dia: 24,
    motivo: 'Día Nacional de la Memoria por la Verdad y la Justicia',
  },

  {
    mes: '4',
    dia: 2,
    motivo: 'Día del Veterano y de los Caídos en la Guerra de Malvinas',
  },
  {
    mes: '4',
    dia: 18,
    motivo: 'Viernes santo',
  },
  {
    mes: '5',
    dia: 1,
    motivo: 'Dia del trabajador',
  },
  {
    mes: '5',
    dia: 2,
    motivo: 'Puente turístico no laborable',
  },
  {
    mes: '5',
    dia: 25,
    motivo: 'Día de la Revolución de Mayo',
  },
  {
    mes: '6',
    dia: 20,
    motivo: 'Paso a la Inmortalidad del General Manuel Belgrano',
  },
  {
    mes: '7',
    dia: 9,
    motivo: 'Día de la Independencia',
  },
  {
    mes: '8',
    dia: 15,
    motivo: 'Puente turístico no laborable',
  },
  {
    mes: '8',
    dia: 17,
    motivo: 'Paso a la Inmortalidad del Gral. José de San Martín',
  },

  {
    mes: '10',
    dia: 12,
    motivo: 'Día del Respeto a la Diversidad Cultural',
  },
  {
    mes: '11',
    dia: 21,
    motivo: 'Puente turístico no laborable',
  },
  {
    mes: '12',
    dia: 8,
    motivo: 'Día de la Inmaculada Concepción de María',
  },
  {
    mes: '12',
    dia: 25,
    motivo: 'Navidad',
  },
];

export const expensesInfarmary = [
  { name: 'DIURESIS' },
  { name: 'DEPOSICION' },
  { name: 'VOMITO-SNG' },
  { name: 'DRENAJE A' },
  { name: 'DRENAJE B' },
  { name: 'DRENAJE C' },
  { name: 'OTROS' },
];

export const MONTHS = [
  ['Enero', 0],
  ['Febrero', 0],
  ['Marzo', 0],
  ['Abril', 0],
  ['Mayo', 0],
  ['Junio', 0],
  ['Julio', 0],
  ['Agosto', 0],
  ['Septiembre', 0],
  ['Octubre', 0],
  ['Noviembre', 0],
  ['Diciembre', 0],
];

export const days = [
  { name: 'Lunes' },
  { name: 'Martes' },
  { name: 'Miercoles' },
  { name: 'Jueves' },
  { name: 'Viernes' },
  { name: 'Sabado' },
  { name: 'Domingo' },
];

export const rangeAges = [
  { start: 0, end: 9 },
  { start: 10, end: 20 },
  { start: 21, end: 30 },
  { start: 31, end: 40 },
  { start: 41, end: 50 },
  { start: 51, end: 60 },
  { start: 61, end: 70 },
  { start: 71, end: 80 },
  { start: 81, end: 90 },
  { start: 91, end: 100 },
  { start: 100, end: 110 },
];

export const countryCodes = [
  {
    name: 'Argentina',
    dial_code: '+54',
    emoji: '🇦🇷',
    code: 'AR',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    emoji: '🇦🇺',
    code: 'AU',
  },
  {
    name: 'Bolivia',
    dial_code: '+591',
    emoji: '🇧🇴',
    code: 'BO',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    emoji: '🇧🇷',
    code: 'BR',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    emoji: '🇨🇦',
    code: 'CA',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    emoji: '🇨🇱',
    code: 'CL',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    emoji: '🇨🇴',
    code: 'CO',
  },

  {
    name: 'Ecuador',
    dial_code: '+593',
    emoji: '🇪🇨',
    code: 'EC',
  },
  {
    name: 'France',
    dial_code: '+33',
    emoji: '🇫🇷',
    code: 'FR',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    emoji: '🇩🇪',
    code: 'DE',
  },

  {
    name: 'Mexico',
    dial_code: '+52',
    emoji: '🇲🇽',
    code: 'MX',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    emoji: '🇵🇾',
    code: 'PY',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    emoji: '🇵🇪',
    code: 'PE',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    emoji: '🇵🇹',
    code: 'PT',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    emoji: '🇷🇺',
    code: 'RU',
  },

  {
    name: 'Spain',
    dial_code: '+34',
    emoji: '🇪🇸',
    code: 'ES',
  },

  {
    name: 'Ukraine',
    dial_code: '+380',
    emoji: '🇺🇦',
    code: 'UA',
  },

  {
    name: 'United States',
    dial_code: '+1',
    emoji: '🇺🇸',
    code: 'US',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    emoji: '🇺🇾',
    code: 'UY',
  },

  {
    name: 'Venezuela',
    dial_code: '+58',
    emoji: '🇻🇪',
    code: 'VE',
  },
];

export const paymentMethods = {
  cash: 'Efectivo',
  creditCard: 'Tarjeta de crédito',
  debitCard: 'Tarjeta de débito',
  transfer: 'Transferencia',
};
