import React from 'react';
import moment from 'moment';
import './IncomeView.scss';
import { useSelector } from 'react-redux';

export default function IncomeData(props) {
  const { income, path } = props;
  const { tenant } = useSelector((store) => store.tenantData);

  return (
    <div className="income-view">
      {path === '/view-income-history/:id' || path === 'epicrisis' ? (
        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Epicrisis</h4>
      ) : (
        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Anamnesis y examen físico
        </h4>
      )}
      <div className="income-view-header">
        <div className="income-view-header__title">
          <text style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {path === '/view-income/:id' &&
              `Cama:
                ${income.bed.name} | `}{' '}
            <text style={{ fontSize: '16px', textTransform: 'capitalize' }}>
              {income.name}
            </text>{' '}
            - {income.age} años{' '}
          </text>
          {income.birthDate && (
            <h6>
              Fecha de nacimiento:{' '}
              <span>{moment(income.birthDate).format('DD/MM/YYYY')}</span>
            </h6>
          )}

          <h6>
            Fecha de ingreso:{' '}
            <span>{moment(income.date).format('DD/MM/YYYY HH:mm')}</span>
          </h6>
          {(path === '/view-income-history/:id' || path === 'epicrisis') && (
            <h6>
              Fecha de alta:{' '}
              <span>{moment(income.dischargeDate).format('DD/MM/YYYY')}</span>
            </h6>
          )}
          <h6>
            {income.bed?.bedType === 'Dialisis'
              ? 'Etiologia de la IRC: '
              : 'Motivo de consulta: '}
            <span>{income.queryReason}</span>
          </h6>
          {(income.dischargeDiagnostic && path === 'epicrisis') ||
            (path === '/view-income-history/:id' && (
              <h6>
                Diagnostico de alta: <span>{income.dischargeDiagnostic}</span>
              </h6>
            ))}
        </div>
        <div className="income-view-header__img">
          <img
            src={
              income.type === 'cardio'
                ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo1.png`
                : `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`
            }
            alt="logo"
          />
        </div>
      </div>
      <div className="income-view-personal">
        <h6>
          Sexo: <span>{income.sex}</span>{' '}
        </h6>
        <h6>
          DNI: <span>{income.dni}</span>{' '}
        </h6>
        <h6>
          Talla: <span>{income.heigth} m</span>{' '}
        </h6>
        <h6>
          Peso: <span>{income.weigth} kg</span>{' '}
        </h6>
        <h6>
          IMC: <span>{income.imc}</span>{' '}
        </h6>
      </div>
      <h6>
        Ciudad de origen:{' '}
        <span style={{ textTransform: 'capitalize' }}>{income.city} </span>
        {income.bed?.bedType === 'Dialisis' && (
          <>
            - Dirección: <span>{income?.direction} </span>
          </>
        )}
        - Origen interno: <span>{income.origin}</span>
      </h6>
      <h6>
        Medico de cabecera: <span>{income.doctor}</span>{' '}
      </h6>
      <h6>
        Obra social:{' '}
        <span style={{ textTransform: 'uppercase' }}>{income.socialWork} </span>{' '}
        - Nro de afiliado: <span>{income.affiliate}</span>
      </h6>
      {income.bed?.bedType === 'Dialisis' && (
        <h6>
          Tipo de paciente: <span>{income?.typePatientDyalisis} </span> - Tipo
          de diálisis: <span>{income?.typeDyalisis}</span>
        </h6>
      )}
      <p>
        <text>Alergias:</text> {income.allergy}
      </p>
      <p>
        <text>Antecedentes: </text>
        {income.personalRecord}
      </p>
      <p>
        <text>Antecedentes quirurgicos: </text> {income.surgicalRecord}
      </p>
      <p>
        <text>Antecedentes toxicos: </text> {income.toxicHabits}
      </p>
      <p>
        <text>Antecedentes heredofamiliares: </text> {income.familyRecord}
      </p>
      <p>
        <text>Estudios previos: </text>
        {income.complementaryStudies}
      </p>
      <p>
        <text>Medicacion habitual: </text>
        {income.regularMediation}
      </p>
      <p>
        <text>Antecedentes de la enfermedad actual: </text>
        {income.historyCurrentIllnes}
      </p>
      {(path === '/view-income/:id' ||
        path === '/add-epicrisis/:id' ||
        path === '/add-epicrisis-history/:id' ||
        path === 'report') && (
        <div>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              Examen fisico:{' '}
            </text>
            {income.physicalExam}
          </p>{' '}
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              Laboratorio:{' '}
            </text>
            {income.laboratory}
          </p>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              ECG:{' '}
            </text>
            {income.ecg}
          </p>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              {income.bed?.bedType === 'Dialisis'
                ? 'Ingreso a Diálisis: '
                : 'Radiografia de torax: '}
            </text>
            {income.rxChest}
          </p>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              Otros estudios:{' '}
            </text>{' '}
            {income.otherStudies}
          </p>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              Conducta:{' '}
            </text>
            {income.conduct}
          </p>
          <p>
            <text
              style={{
                color:
                  (path === '/add-epicrisis/:id' ||
                    path === '/add-epicrisis-history/:id') &&
                  'red',
              }}
            >
              Diagnostico presuntivo:{' '}
            </text>{' '}
            {income.diagnostic}
          </p>
        </div>
      )}
      {(path === '/view-income/:id' ||
        path === '/add-epicrisis/:id' ||
        path === '/add-epicrisis-history/:id') && (
        <p>
          <text
            style={{
              color:
                (path === '/add-epicrisis/:id' ||
                  path === '/add-epicrisis-history/:id') &&
                'red',
            }}
          >
            Novedades:{' '}
          </text>
          {income.news}
        </p>
      )}
      {path === 'report' && (
        <h6
          style={{
            textAlign: 'right',
            marginTop: '1%',
            marginRight: '2%',
            textTransform: 'capitalize',
          }}
        >
          {income?.userDoctorIncome?.name} {income?.userDoctorIncome?.lastname}
        </h6>
      )}
    </div>
  );
}
