import React from 'react';
import moment from 'moment';
import '../../ListAndDetail/ViewInfo.scss';

export function ViewVascularAccess(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  return (
    <div className="view-info">
      <h5>Acceso Vacular</h5>
      <h6>
        Fecha de confección: {moment(data?.date).format('DD/MM/YYYY HH:mm')}
      </h6>
      <h6>Estado: {data.status}</h6>
      <h6>Tipo de acceso: {data.type}</h6>
      <h6>Ubicación: {data.location}</h6>
      <h6>Notas:</h6>
      <p>{data?.notes}</p>
      <div className="view-info__signature">
        <h4>
          {data?.user?.name} {data?.user?.lastname}
        </h4>
        {data?.user?.licenseType && (
          <div>
            <p>{data?.user?.type}</p>
            <p>
              {data?.user?.medicalSpeciality &&
                `ESP. en ${data?.user?.medicalSpeciality}`}
            </p>
            <p>
              {data?.user?.mp &&
                `${data?.user?.licenseType === 'nacional' ? 'MN' : 'MP'} ${
                  data?.user?.mp
                }`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
