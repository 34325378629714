import LayoutAdmin from '../layouts/LayoutAdmin';

import Home from '../pages/Home/Home';
import SignIn from '../pages/SignIn';
import Users from '../pages/Users';
import Income from '../pages/Income';
import IncomesHistory from '../pages/IncomesHistory';

import Error404 from '../pages/Error404';
import IncomeForm from '../components/IncomeForm';
import IncomeView from '../components/IncomeView';
import IncomePass from '../components/IncomePass';
import DashBoard from '../pages/DashBoard';
import Calculator from '../pages/Calculator';
import Phones from '../pages/Phones';
import Configuration from '../pages/Configuration';
import Evolution from '../pages/Evolution';
import Protocolo from '../pages/Protocolos';
import Medicine from '../pages/Medicine';
import Ambulatory from '../pages/Ambulatory';

import IncomeTableInfarmary from '../pages/Infarmary/IncomeInfarmary';
import Notes from '../pages/Infarmary/Notes';
import Controls from '../pages/Infarmary/Controls';
import DyalisisControls from '../pages/Infarmary/DyalisisControls';

import Balance from '../pages/Infarmary/Balance';
import ViewInfarmary from '../pages/ViewInfarmary';
import IncomeReport from '../components/IncomeReport';
import MessageHistory from '../pages/MessageHistory';
import PdfReports from '../pages/PdfReports';
import Calendar from '../pages/Calendar';
import NewDosis from '../components/NewDosis/NewDosis';
import OperationForm from '../components/OperationForm';
import Operation from '../pages/Operation/Operation';
import Consultation from '../pages/Consultation';
import NewConsultation from '../pages/NewConsultation';
import Turns from '../pages/Turns';
import Insumos from '../pages/Infarmary/Insumos';
import Pharmacy from '../pages/Pharmacy/Pharmacy';
import NewBuyPharmacy from '../pages/Pharmacy/NewBuyPharmacy';
import PharmacyStock from '../pages/Pharmacy/PharmacyStock';
import NewSendPharmacy from '../pages/Pharmacy/NewSendPharmacy';
import PharmacyList from '../pages/Pharmacy/PharmacyList';
import Billing from '../pages/Billing/Billing';
import NewBatchBilling from '../pages/Billing/NewBatchBilling';
import BillingBatchDetails from '../pages/Billing/BillingBatchDetails';
import BillingDetail from '../pages/Billing/BillingDetail';
import CashRegister from '../pages/CashRegister';
import Nomenclator from '../pages/Nomenclator/Nomenclator';
import BankAccount from '../pages/BankAccount/BankAccount';
import VascularAccess from '../pages/Dyalisis/VascularAccess';

const routes = [
  {
    path: '/',
    exact: false,

    component: LayoutAdmin,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/income/:incomeType',
        exact: true,
        component: Income,
      },
      {
        path: '/login',
        exact: true,
        component: SignIn,
      },
      {
        path: '/new-income/:incomeType',
        exact: true,
        component: IncomeForm,
      },
      {
        path: '/edit-income/:id/:incomeType',
        exact: true,
        component: IncomeForm,
      },
      {
        path: '/edit-history-income/:id',
        exact: true,
        component: IncomeForm,
      },
      {
        path: '/view-income/:id',
        exact: true,
        component: IncomeView,
      },
      {
        path: '/view-pass/:type',
        exact: true,
        component: IncomePass,
      },
      {
        path: '/add-epicrisis/:id',
        exact: true,
        component: IncomeView,
      },
      {
        path: '/add-epicrisis-history/:id',
        exact: true,
        component: IncomeView,
      },
      {
        path: '/incomes-history',
        exact: true,
        component: IncomesHistory,
      },
      {
        path: '/view-income-history/:id',
        exact: true,
        component: IncomeView,
      },
      {
        path: '/users',
        exact: true,
        component: Users,
      },
      {
        path: '/charts',
        exact: true,
        component: DashBoard,
      },
      {
        path: '/ambulatory/:favourite?',
        exact: false,
        component: Ambulatory,
      },
      {
        path: '/ambulatory-consultations/:patientId/:patientName',
        exact: true,
        component: Consultation,
      },
      {
        path: '/new-consultation/:patientId',
        exact: true,
        component: NewConsultation,
      },
      {
        path: '/incomes-history-patient/:dni/:name',
        exact: true,
        component: IncomesHistory,
      },
      {
        path: '/patient-studies/:id/:name?',
        exact: true,
        component: PdfReports,
      },
      {
        path: '/calculator',
        exact: true,
        component: Calculator,
      },
      {
        path: '/phones',
        exact: true,
        component: Phones,
      },
      {
        path: '/calendar',
        exact: true,
        component: Calendar,
      },
      {
        path: '/turns',
        exact: true,
        component: Turns,
      },
      {
        path: '/pharmacy',
        exact: true,
        component: Pharmacy,
      },
      {
        path: '/configuration',
        exact: true,
        component: Configuration,
      },
      {
        path: '/message-history',
        exact: true,
        component: MessageHistory,
      },
      {
        path: '/evolution/:id',
        exact: true,
        component: Evolution,
      },
      {
        path: '/evolution-history/:id',
        exact: true,
        component: Evolution,
      },
      {
        path: '/evolution-history/:id',
        exact: true,
        component: Evolution,
      },
      {
        path: '/medicine/:id',
        exact: true,
        component: Medicine,
      },
      {
        path: '/medicine-history/:id',
        exact: true,
        component: Medicine,
      },
      {
        path: '/new-medicine/:incomeId/:incomeType?/:medicineId?',
        exact: true,
        component: NewDosis,
      },
      {
        path: '/update-medicine/:incomeId/:medicineId?',
        exact: true,
        component: NewDosis,
      },
      {
        path: '/protocolo',
        exact: true,
        component: Protocolo,
      },
      {
        path: '/report/:id',
        exact: true,
        component: IncomeReport,
      },
      {
        path: '/pdf-report/:id/:name?',
        exact: true,
        component: PdfReports,
      },
      {
        path: '/operating/:id',
        exact: true,
        component: Operation,
      },
      {
        path: '/operating-history/:id',
        exact: true,
        component: Operation,
      },
      {
        path: '/new-operation/:id/:name/:bed/:type',
        exact: true,
        component: OperationForm,
      },
      {
        path: '/edit-operation/:operationId/:name/:bed/:type',
        exact: true,
        component: OperationForm,
      },
      {
        path: '/infarmary-income/:incomeType',
        exact: true,
        component: IncomeTableInfarmary,
      },
      {
        path: '/notes/:id/:history?',
        exact: true,
        component: Notes,
      },
      {
        path: '/controls/:id/:history?',
        exact: true,
        component: Controls,
      },
      {
        path: '/dialisis-controls/:id/:view?/:history?',
        exact: true,
        component: DyalisisControls,
      },
      {
        path: '/insumos/:id/:history?',
        exact: true,
        component: Insumos,
      },
      {
        path: '/balances/:id/:name?',
        exact: true,
        component: Balance,
      },
      {
        path: '/view-infarmary/:id',
        exact: true,
        component: ViewInfarmary,
      },
      {
        path: '/pharmacy/buy',
        exact: true,
        component: NewBuyPharmacy,
      },
      {
        path: '/pharmacy/send',
        exact: true,
        component: NewSendPharmacy,
      },
      {
        path: '/pharmacy/stock/:id?',
        exact: true,
        component: PharmacyStock,
      },
      {
        path: '/view-pharmacy/:type',
        exact: true,
        component: PharmacyList,
      },
      {
        path: '/billing',
        exact: true,
        component: Billing,
      },
      {
        path: '/new-batch-billing',
        exact: true,
        component: NewBatchBilling,
      },
      {
        path: '/billing-batch-details/:type',
        exact: true,
        component: BillingBatchDetails,
      },
      {
        path: '/billing-detail/:id',
        exact: true,
        component: BillingDetail,
      },
      {
        path: '/cash-register',
        exact: true,
        component: CashRegister,
      },
      {
        path: '/bank-account/:bankAccount',
        exact: true,
        component: BankAccount,
      },
      {
        path: '/nomenclators',
        exact: true,
        component: Nomenclator,
      },
      {
        path: '/vascular-access/:id',
        exact: true,
        component: VascularAccess,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
