import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import {
  PdfHeader,
  PdfEvolution,
  PdfTitle,
  PdfSignature,
  PdfFooter,
  PdfPatientData,
} from './index';
import { styleBody } from './styles';

export function PdfConsultation(props) {
  const {
    patient,
    dataConsultations,
    organization,
    tenant,
    title,
    tenantLogo,
  } = props;

  return (
    <Document>
      <Page style={styleBody.body}>
        <PdfHeader
          organization={organization}
          tenant={tenant}
          tenantLogo={tenantLogo}
        />
        <View>
          <PdfPatientData patient={patient} />
          <Text style={styleBody.subtitle}>{title}</Text>
          {dataConsultations?.consultations.map((data, index) => (
            <View key={index}>
              <PdfEvolution data={data} />
              <PdfSignature
                signature={dataConsultations?.signatures[index]}
                name={data?.user?.name}
                lastname={data?.user?.lastname}
                medicalSpeciality={data?.user?.medicalSpeciality}
                userType={data?.user?.type}
                mp={data?.user?.mp}
                licenseType={data?.user?.licenseType}
              />
            </View>
          ))}
        </View>
        <PdfFooter type="consulta" />
      </Page>
    </Document>
  );
}
