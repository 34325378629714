import React, { useState, useEffect } from 'react';
import { Button, Tooltip, PageHeader, message, Modal as ModalAntd } from 'antd';
import Modal from '../../components/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAccessTokenApi } from '../../api/auth';
import { ListDates, PageListAndView } from '../../components/ListAndDetail';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ViewVascularAccess } from '../../components/Dyalisis/VascularAccess/ViewVascularAccess';
import VascularAccessForm from '../../components/Dyalisis/VascularAccess/VascularAccessForm';
import {
  deleteVascularAccessApi,
  getVascularAccessApi,
} from '../../api/vascularAccess';
import { ROLES } from '../../utils/constants';
import PermissionsAsigner from '../../components/PermissionsAsigner';

export default function Notes(props) {
  const { id, history } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState(null);
  const [vascularAccess, setVascularAccess] = useState();
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState('desc');

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      const response = await getVascularAccessApi(id, order, token);

      setVascularAccess(response?.data);

      response?.data?.length > 0
        ? setData({
            status: response.data[0]?.status,
            location: response.data[0]?.location,
            type: response.data[0]?.type,
            notes: response.data[0]?.notes,
            user: response.data[0]?.user,
            date: response.data[0]?.date,
            id: response.data[0]?._id,
          })
        : setData();

      setReload(false);
    };
    getData();
  }, [reload, order]);

  const viewData = (vascularAccess) => {
    setData({
      status: vascularAccess?.status,
      location: vascularAccess?.location,
      type: vascularAccess?.type,
      notes: vascularAccess?.notes,
      user: vascularAccess?.user,
      date: vascularAccess?.date,
      id: vascularAccess?._id,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  var income = {};
  history
    ? incomesHistory.find((i) => {
        if (i._id === id) {
          income = i;
        }
      })
    : incomesActive.find((i) => {
        if (i._id === id) {
          income = i;
        }
      });

  const addVascularAccess = (data) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar acceso vascular a ${income.name}`);
    setModalContent(
      <VascularAccessForm
        incomeId={id}
        data={data}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
      />
    );
  };

  const viewAll = () => {
    const data = [];

    vascularAccess &&
      vascularAccess.map((item) =>
        data.push({
          status: item?.status,
          location: item?.location,
          type: item?.type,
          notes: item?.notes,
          user: item?.user,
          date: item?.date,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const deleteVascularAccess = () => {
    ModalAntd.confirm({
      title: 'Eliminar acceso vascular',
      content: `¿ Estas seguro que quieres eliminar el acceso vascular del ${moment(
        data.date
      ).format('DD-MM HH:mm')}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          const token = await getAccessTokenApi();
          const response = await deleteVascularAccessApi(data.id, token);
          if (response?.code !== 200) {
            message.error(response.message);
          } else {
            message.success(response.message);
            setReload(true);
          }
        } catch {
          message.error('Error del servidor, intente mas tarde.');
        }
      },
    });
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`Cama: ${income.bed.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income.bed.name} ${income.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income.name} - {income.age} años | Fecha Ingreso:{' '}
              {moment(income.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            <Button type="primary" onClick={() => addVascularAccess()}>
              Nuevo acceso vascular
            </Button>
          }
        />
      </div>

      <PageListAndView
        datesTitle="Accesos vasculares"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        componentDate={<ListDates data={vascularAccess} viewData={viewData} />}
        detailTitle="Detalle"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              {dataAll?.map((data, index) => (
                <>
                  <ViewVascularAccess data={data} />
                </>
              ))}
            </div>
          )
        }
        componentDetail={<ViewVascularAccess data={data} />}
        extra={
          <div>
            <Tooltip title="Editar">
              <Button
                type="link"
                disabled={!data}
                onClick={() => addVascularAccess(data)}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <PermissionsAsigner
              requiredRole={ROLES.OWNER}
              visible={ROLES.SECRETARY}
            >
              <Tooltip title="Eliminar">
                <Button
                  type="link"
                  onClick={() => deleteVascularAccess()}
                  disabled={!data}
                >
                  <DeleteOutlined style={data && { color: 'red' }} />
                </Button>
              </Tooltip>
            </PermissionsAsigner>
          </div>
        }
      />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={window.screen.width < 500 ? '80%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
